import gql from "graphql-tag";
import { TextAndText as TextAndTextProps } from "../../../../__generated__/TextAndText";
import { ContentfulComponentType } from "../types";
import styles from "./TextAndText.module.css";
import TextFields from "../TextFields";

export const ContentfulTextAndText: ContentfulComponentType<
  TextAndTextProps
> = ({
  leftHighlight,
  leftTitle,
  leftText,
  rightHighlight,
  rightTitle,
  rightText,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <TextFields
          title={leftTitle}
          highlight={leftHighlight}
          text={leftText}
          variantText="body2"
          gutterBottom={0}
        />
      </div>
      <div className={styles.textContainer}>
        <TextFields
          title={rightTitle}
          highlight={rightHighlight}
          text={rightText}
          variantText="body2"
          gutterBottom={0}
        />
      </div>
    </div>
  );
};

ContentfulTextAndText.fragment = gql`
  fragment TextAndText on ComponentTextAndText {
    leftHighlight
    leftTitle
    leftText
    rightHighlight
    rightTitle
    rightText
    sys {
      id
      publishedAt
    }
  }
`;
