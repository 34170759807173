import React, { useEffect, useState } from "react";
import gql from "graphql-tag";
import Image from "next/image";
import { Trans } from "react-i18next";
import { useWebTranslation } from "../../../translations";
import StandardHero from "./StandardHero";
import PuppyHero from "./PuppyHero";
import GermanOOHHero from "./GermanOOHHero";
import { ContentfulComponentType } from "../types";
import {
  Hero as ComponentHero,
  Hero_listItemsCollection_items,
} from "../../../../__generated__/Hero";
import styles from "./Hero.module.css";
import { useRouter } from "next/router";
import Gutter from "ui-kit/Gutter";
import { ContentfulC2A, getButtonColors } from "../ContentfulC2A";
import TextFields from "../TextFields";
import ContentfulMedia from "components-shared/ContentfulMedia";
import { ThemeEnum, getThemeColors } from "../theme";
import Background from "../Background";
import { FlexCol, FlexRow } from "ui-kit/Layout/Flex";
import { Typography } from "ui-kit/Typography";
import { ListStyleEnum } from "../List";
import ListStyle from "../List/ListStyle";
import { speciesToEmoji } from "utils/insurance";
import { logEvent } from "analytics";
import { Button } from "ui-kit/Button";
import { SpeciesEnum } from "../../../../__generated__/globalTypes";
import Colors from "ui-kit/colors";
import Hero from "components/Hero";
import { resumePreviewStore } from "components/Signup/flow/utils/previewStore";
import { useResumePreviewStore } from "./useResumePreviewStore";

export enum HeroTypeEnum {
  StandardHero = "Standard Hero",
  StartpageHero = "Startpage Hero",
  StartpageHeroAll = "Startpage Hero - All",
  StartpageHeroCat = "Startpage Hero - Cat",
  StartpageHeroDog = "Startpage Hero - Dog",
  KittenCampaignHero = "Kitten Campaign Hero",
  PuppyCampaignHero = "Puppy Campaign Hero",
  GermanOOHCampaignHero = "German OOH Campaign Hero",
  PetsDeliHero = "Pets Deli Hero",
}

const shieldsSrcs = {
  [HeroTypeEnum.StartpageHeroAll]: "/images/shields/midi.svg",
  [HeroTypeEnum.StartpageHeroCat]: "/images/shields/cat-midi.svg",
  [HeroTypeEnum.StartpageHeroDog]: "/images/shields/dog-midi.svg",
};

const HeroList = ({
  listItems,
}: {
  listItems: (Hero_listItemsCollection_items | null)[];
}) => {
  return listItems.length > 0 ? (
    <>
      <Gutter size={3} />
      <ul className={styles.list}>
        {listItems
          .flatMap((item) => (item ? [item] : []))
          .map((item, index) => (
            <li key={item.title} className={styles.listItem}>
              <ListStyle listStyle={ListStyleEnum.Check} index={index} />
              <Typography variant="body1">{item.title}</Typography>
            </li>
          ))}
      </ul>
    </>
  ) : null;
};

export const ContentfulHero: ContentfulComponentType<ComponentHero> = ({
  type,
  title,
  text,
  theme,
  listItemsCollection,
  media,
  thumbnail,
  c2A,
}) => {
  const { t } = useWebTranslation();
  const router = useRouter();
  const themeColors = getThemeColors(theme ?? ThemeEnum.Yellow);
  const listItems = listItemsCollection?.items ?? [];

  const isStandardHero = !type || type === HeroTypeEnum.StandardHero;
  const isStartPageHero = [
    HeroTypeEnum.StartpageHeroAll,
    HeroTypeEnum.StartpageHeroCat,
    HeroTypeEnum.StartpageHeroDog,
  ].includes(type as HeroTypeEnum);

  const signUpState = useResumePreviewStore();

  return (
    <>
      {type === HeroTypeEnum.StartpageHero && (
        <StandardHero title={title} text={text} media={media} c2a={c2A} />
      )}
      {type === HeroTypeEnum.PuppyCampaignHero && (
        <PuppyHero
          title={<Trans i18nKey="puppyhero.dog.title" />}
          buttonSubText={t("hero.button_sub_text")}
          usps={[
            t("puppyhero.dog.usp1"),
            t("puppyhero.dog.usp2"),
            t("puppyhero.dog.usp3"),
          ]}
          species="dog"
        />
      )}
      {type === HeroTypeEnum.KittenCampaignHero && (
        <PuppyHero
          title={<Trans i18nKey="puppyhero.cat.title" />}
          buttonSubText={t("hero.button_sub_text")}
          usps={[
            t("puppyhero.cat.usp1"),
            t("puppyhero.cat.usp2"),
            t("puppyhero.cat.usp3"),
          ]}
          species="cat"
        />
      )}
      {type === HeroTypeEnum.GermanOOHCampaignHero && (
        <GermanOOHHero
          title={`Kleiner Beitrag, Große Tierliebe.`}
          buttonSubText={t("hero.button_sub_text")}
          species="cat"
          usps={[t("hero.usp1"), t("hero.usp2"), t("hero.usp3")]}
        />
      )}
      {type === HeroTypeEnum.PetsDeliHero && (
        <Hero
          title={t("petsdelihero.title")}
          usps={[
            t("petsdelihero.usp1"),
            t("petsdelihero.usp2"),
            t("petsdelihero.usp3"),
          ]}
          buttonSubText={t("hero.button_sub_text")}
          onButtonClick={() =>
            router.push("/signup?discountCode=PETSDELI-SHOP")
          }
          imageSrc="/images/pets-deli.png"
        />
      )}
      {isStartPageHero && (
        <Background background={themeColors?.backgroundGradient}>
          <div className={styles.containerStartPage}>
            <div className={styles.textContainerStartPage}>
              <TextFields
                title={title}
                text={text}
                variantTitle="h1"
                gutterBottom={0}
                center={!media}
              />
              <HeroList listItems={listItems} />
              {!!c2A && (
                <FlexCol
                  alignItems={media ? "flex-start" : "center"}
                  justifyContent="center"
                  className={styles.buttonContainer}
                >
                  <Gutter size={4} />

                  <Button
                    variant="custom"
                    title={c2A.title!}
                    onPress={() => {
                      logEvent("USER_HOMEPAGE_GET_QUOTE");
                      if (c2A.destination?.__typename === "Url") {
                        router.push(c2A.destination.url!);
                      }
                    }}
                    color={getButtonColors(c2A.color).color}
                    textColor={getButtonColors(c2A.color).textColor}
                  />
                  {signUpState?.previewId && (
                    <>
                      <Gutter size={1.5} />
                      <Button
                        variant="custom"
                        color="white"
                        textColor="blue300"
                        title={t("home.sections.0.btn_resume_quote", {
                          name: signUpState.name ?? "?",
                          emoji: speciesToEmoji(
                            signUpState.species as SpeciesEnum
                          ),
                        })}
                        onPress={() => {
                          logEvent("USER_HOMEPAGE_RESUME_QUOTE");

                          const page = signUpState.isLiability
                            ? "liability-quote-preview"
                            : "quote-preview";

                          router.push(
                            `/signup/${page}?id=${signUpState.previewId}`
                          );
                        }}
                      />
                    </>
                  )}
                </FlexCol>
              )}
            </div>
            {media && (
              <div className={styles.mediaContainerStartPage}>
                <div className={styles.topBadge}>
                  <FlexCol
                    justifyContent="center"
                    alignItems="center"
                    className={styles.badgeContent}
                  >
                    <Typography variant="h5" color="green300" align="center">
                      {t("hero.pets_prefix")}
                    </Typography>
                    <Typography variant="h2" color="green300" align="center">
                      {t("hero.pets_number")}
                    </Typography>
                    <Typography variant="h5" color="green300" align="center">
                      {t("hero.pets_suffix")}
                    </Typography>
                  </FlexCol>
                </div>
                <ContentfulMedia
                  media={media}
                  thumbnail={thumbnail}
                  sizes="450px"
                  display="round"
                />
                <div className={styles.bottomBadge}>
                  <FlexRow
                    justifyContent="center"
                    alignItems="center"
                    className={styles.badgeContent}
                  >
                    <Image
                      src={shieldsSrcs[type as keyof typeof shieldsSrcs]}
                      alt={t("global.insurance")}
                      width={70}
                      height={70}
                      className={styles.shield}
                    />
                  </FlexRow>
                </div>
              </div>
            )}
          </div>
        </Background>
      )}
      {isStandardHero && (
        <Background
          background={themeColors && Colors[themeColors.backgroundColor]}
        >
          <div className={styles.container}>
            <div className={styles.textContainer}>
              <TextFields
                title={title}
                text={text}
                variantTitle="h1"
                gutterBottom={0}
                center={!media}
              />
              <HeroList listItems={listItems} />
              {!!c2A && (
                <FlexCol
                  alignItems={media ? "flex-start" : "center"}
                  justifyContent="center"
                >
                  <Gutter size={4} />
                  <ContentfulC2A {...c2A} />
                </FlexCol>
              )}
            </div>
            {media && (
              <div className={styles.mediaContainer}>
                <ContentfulMedia media={media} sizes="400px" display="square" />
              </div>
            )}
          </div>
        </Background>
      )}
    </>
  );
};

ContentfulHero.fragment = gql`
  fragment Hero on ComponentHero {
    type
    title
    text
    theme
    c2A {
      ...C2A
    }
    listItemsCollection(limit: 10) {
      items {
        title
        text
        icon
      }
    }
    thumbnail {
      title
      url
      height
      width
      contentType
    }
    media {
      title
      url
      height
      width
      contentType
    }
    sys {
      id
      publishedAt
    }
  }
`;
