import { FlexCol } from "ui-kit/Layout/Flex";
import { ListItem } from ".";
import classNames from "classnames";
import styles from "./List.module.css";
import TextFields from "../TextFields";

const ItemText = ({
  text,
  noGutter,
  isVisible,
}: {
  text?: ListItem["text"];
  noGutter?: boolean;
  isVisible: boolean;
}) => (
  <FlexCol className={classNames(styles.text, isVisible && styles.open)}>
    <TextFields
      text={text}
      variantText="body2"
      gutterGap={noGutter ? 0 : 2}
      gutterBottom={0}
    />
  </FlexCol>
);

export default ItemText;
