import { TextAndMedia as TextAndMediaProps } from "../../../../__generated__/TextAndMedia";
import ContentfulMedia from "../../../components-shared/ContentfulMedia";

import Gutter from "../../../ui-kit/Gutter";
import styles from "./ContentfulCards.module.css";
import { FC } from "react";
import TextFields from "../TextFields";
import { ContentfulC2A } from "../ContentfulC2A";
import List, { ListStyleEnum } from "../List";
import { FlexCol } from "ui-kit/Layout/Flex";

export const TextAndMediaCard: FC<TextAndMediaProps> = ({
  media,
  intro,
  title,
  text,
  listLayout,
  listStyle,
  listItemsCollection,
  c2A,
}) => {
  const listItems = listItemsCollection?.items ?? [];

  return (
    <div className={styles.cardContainer}>
      <ContentfulMedia media={media} sizes="500px" display="header" />
      <FlexCol className={styles.textContainer}>
        <TextFields
          title={title}
          intro={intro}
          text={text}
          variantTitle="h3"
          variantText="body2"
          gutterBottom={0}
        />
        {!!listItems.length && (
          <>
            <Gutter size={3} />
            <List
              items={listItems}
              listStyle={listStyle as ListStyleEnum}
              collapsible={!!listLayout}
            />
          </>
        )}
        {c2A && (
          <div className={styles.buttonWrapper}>
            <ContentfulC2A
              {...c2A}
              style={true}
              color="blue300"
              iconButtonclassName={styles.button}
            />
          </div>
        )}
      </FlexCol>
    </div>
  );
};
