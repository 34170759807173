import gql from "graphql-tag";
import { TextAndMedia as TextAndMediaProps } from "../../../../__generated__/TextAndMedia";
import { ContentfulComponentType } from "../types";
import styles from "./TextAndMedia.module.css";
import TextFields from "../TextFields";
import Gutter from "ui-kit/Gutter";
import { ContentfulC2A } from "../ContentfulC2A";
import List, { ListStyleEnum } from "../List";
import { getThemeColors } from "../theme";
import ContentfulMedia from "components-shared/ContentfulMedia";
import Background from "../Background";
import Colors from "ui-kit/colors";

export const ContentfulTextAndMedia: ContentfulComponentType<
  TextAndMediaProps
> = ({
  theme,
  layout,
  media,
  title,
  highlight,
  text,
  intro,
  listItemsCollection,
  listLayout,
  listStyle,
  c2A,
}) => {
  const themeColors = getThemeColors(theme);
  const listItems = listItemsCollection?.items ?? [];

  return (
    <Background background={themeColors && Colors[themeColors.backgroundColor]}>
      <div className={layout! ? styles.container : styles.containerReverse}>
        <div className={styles.mediaContainer}>
          <ContentfulMedia
            media={media}
            sizes="(max-width: 768px) 100vw, (max-width: 1080px) 40vw, 476px"
            display="square"
          />
        </div>
        <div className={styles.textContainer}>
          <TextFields
            title={title}
            highlight={highlight}
            highlightColor={themeColors?.highlightColor}
            text={text}
            intro={intro}
            variantText="body2"
            gutterBottom={0}
          />
          {listItems.length > 0 && (
            <>
              <Gutter size={3} />
              <List
                items={listItems}
                listStyle={listStyle as ListStyleEnum}
                collapsible={!!listLayout}
              />
            </>
          )}
          {!!c2A && (
            <>
              <Gutter size={4} />
              <ContentfulC2A {...c2A} />
            </>
          )}
        </div>
      </div>
    </Background>
  );
};

ContentfulTextAndMedia.fragment = gql`
  fragment TextAndMedia on ComponentTextAndMedia {
    highlight
    title
    intro
    text
    theme
    layout
    listItemsCollection(limit: 10) {
      items {
        title
        text
        icon
      }
    }
    listLayout
    listStyle
    c2A {
      ...C2A
    }
    media {
      title
      url
      height
      width
      contentType
    }
    sys {
      id
      publishedAt
    }
  }
`;
