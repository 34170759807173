import React, { ReactElement } from "react";
import Image from "next/image";
import styles from "./OOHHero.module.css";
import { Button } from "../../../ui-kit/Button";
import StarImage from "../../../assets/images/star.svg";
import { useWebTranslation } from "../../../translations";
import TrustPilotStars from "../../TrustPilotStars";
import { useRouter } from "next/router";
import Gutter from "../../../ui-kit/Gutter";
import { FlexRow } from "../../../ui-kit/Layout/Flex";
import { Typography } from "../../../ui-kit/Typography";
import heroImage from "../../../assets/images/oohhero.png";
import Colors from "../../../ui-kit/colors";
import { replaceCommonMarkup } from "utils/utils";

type HeroProps = {
  title: ReactElement | string;
  tagLine?: string | ReactElement;
  usps?: string[];
  buttonTitle?: string;
  onButtonClick?: () => void;
  buttonColor?: string;
  buttonSubText?: string;
  species?: string;
};

export default function GermanOOHHero({
  title,
  tagLine,
  usps,
  buttonTitle,
  onButtonClick,
  buttonColor = Colors.green200,
  buttonSubText,
  species,
}: HeroProps): ReactElement {
  const { t } = useWebTranslation();
  const router = useRouter();

  const onClick = () => {
    if (typeof onButtonClick === "function") {
      return onButtonClick();
    }
    router.push("/signup?discountCode=LASSIERABATT15");
    return true;
  };

  return (
    <div>
      <main className={styles.container}>
        <div className={styles.content}>
          <div className={styles.image}>
            <>
              <Image
                src={heroImage}
                alt="Lassie"
                priority
                className={styles.desktopImage}
                sizes="(max-width: 900px) 100vw, 900px"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <Image
                src={heroImage}
                alt="Lassie"
                priority
                className={styles.mobileImage}
                sizes="(max-width: 900px) 100vw, 900px"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
            </>
            <Typography
              className={styles.mobileImage}
              variant="body3"
              style={{
                position: "absolute",
                bottom: -50,
                left: 0,
                right: 0,
                padding: 0,
              }}
            >
              *Erhalte 15€ Rabatt auf dein erstes Versicherungsjahr.
            </Typography>
            <Typography
              className={styles.desktopImage}
              variant="body3"
              style={{
                position: "absolute",
                bottom: -50,
                left: 0,
                right: 0,
                textAlign: "center",
              }}
            >
              *Erhalte 15€ Rabatt auf dein erstes Versicherungsjahr.
            </Typography>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className={styles.textContent}>
              <div className={styles.titles}>
                <Typography variant="h1" className={styles.title}>
                  {title}
                </Typography>
              </div>
              {tagLine && (
                <div data-test-id="tagLine" className={styles.tagLine}>
                  <div>{tagLine}</div>
                </div>
              )}
              {usps && (
                <div data-test-id="tagLine" className={styles.usps}>
                  <ul className={styles.uspList}>
                    {usps.map((usp) => (
                      <li className={styles.uspListItem} key={usp}>
                        <FlexRow alignItems="flex-start">
                          <Image
                            src={StarImage}
                            alt="star"
                            width={26}
                            height={24.5}
                          />
                          <Gutter size={1} horizontal />
                          <Typography>{replaceCommonMarkup(usp)}</Typography>
                        </FlexRow>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div
                style={{
                  maxHeight: "100vh",
                }}
              >
                <div className={styles.button}>
                  <Button
                    big
                    textStyle={{
                      fontSize: 16,
                      fontFamily: "var(--font-eina-bold",
                    }}
                    variant={"custom"}
                    color={"green200"}
                    onPress={onClick}
                    title={buttonTitle || t("home.sections.0.btn")}
                  />
                  {buttonSubText && (
                    <>
                      <Gutter size={1} />
                      <Typography
                        style={{ fontSize: 12, textAlign: "center" }}
                        component="div"
                      >
                        {buttonSubText}
                      </Typography>
                    </>
                  )}
                </div>
              </div>
              <div className={styles.trustpilot}>
                <TrustPilotStars />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
GermanOOHHero.defaultProps = {
  onButtonClick: false,
  hidePress: false,
  buttonColor: Colors.green200,
};
