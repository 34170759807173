import React, { ReactElement, CSSProperties, ReactNode } from "react";
import styles from "./Container.module.css";

interface Props {
  children: ReactNode | ReactNode[];
  style?: CSSProperties;
}

const Container = ({ children, style }: Props): ReactElement => {
  return (
    <div className={styles.wrapper} style={style}>
      {children}
    </div>
  );
};

Container.defaultProps = {
  style: undefined,
};

export default Container;
