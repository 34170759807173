import Image from "next/image";
import styles from "./Appstores.module.css";
import reviewStyles from "../ReviewsSection.module.css";
import { Typography } from "ui-kit/Typography";
import classNames from "classnames";
import Gutter from "ui-kit/Gutter";

const Store = ({
  name,
  logo,
  rating,
}: {
  name: string;
  logo: string;
  rating: number;
}) => {
  return (
    <div className={styles.container}>
      <Image src={logo} width={24} height={24} alt="" />
      <div className={styles.content}>
        <div>
          <Typography variant="body2" style={{ marginInlineEnd: 4 }}>
            {rating}
          </Typography>
          <Image src="/images/hero/star.svg" width={12} height={12} alt="" />
          <Image src="/images/hero/star.svg" width={12} height={12} alt="" />
          <Image src="/images/hero/star.svg" width={12} height={12} alt="" />
          <Image src="/images/hero/star.svg" width={12} height={12} alt="" />
          <Image
            src="/images/hero/star-half.svg"
            width={12}
            height={12}
            alt=""
          />
        </div>
        <Typography variant="body4">{name}</Typography>
      </div>
    </div>
  );
};

export const AppStores = () => {
  return (
    <div className={classNames(reviewStyles.review, styles.wrapper)}>
      <Store name="App Store" logo="/images/hero/appstore.svg" rating={4.8} />
      <div className={styles.divider} />
      <Store
        name="Google Play"
        logo="/images/hero/playstore.svg"
        rating={4.7}
      />
    </div>
  );
};
