import React from "react";
import { gql } from "@apollo/client";
import RichText from "../../../components-shared/RichText";
import { RichText as RichTextProps } from "../../../../__generated__/RichText";
import { ContentfulComponentType } from "../types";
import TextFields from "../TextFields";
import Gutter from "ui-kit/Gutter";
import { ContentfulC2A } from "../ContentfulC2A";
import { FlexCol } from "ui-kit/Layout/Flex";
import { getThemeColors } from "../theme";
import Background from "../Background";
import Colors from "ui-kit/colors";

export const ContentfulRichText: ContentfulComponentType<RichTextProps> = ({
  highlight,
  title,
  text,
  theme,
  richText,
  c2A,
}) => {
  const themeColors = getThemeColors(theme);

  return (
    <Background background={themeColors && Colors[themeColors.backgroundColor]}>
      <TextFields
        highlight={highlight}
        highlightColor={themeColors?.highlightColor}
        title={title}
        text={text}
        gutterBottom={richText ? 5 : 0}
        center
      />
      <RichText json={richText?.json} assets={[]} />
      {c2A && (
        <FlexCol alignItems="center" justifyContent="center">
          <Gutter size={4} />
          <ContentfulC2A {...c2A} />
        </FlexCol>
      )}
    </Background>
  );
};

ContentfulRichText.fragment = gql`
  fragment RichText on ComponentRichText {
    highlight
    title
    text
    theme
    richText {
      json
    }
    c2A {
      ...C2A
    }
    sys {
      id
      publishedAt
    }
  }
`;
