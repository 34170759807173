import React, {
  FC,
  RefCallback,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionTitle,
} from "@/ui-kit";
import { FlexCol } from "../../ui-kit/Layout/Flex";
import styles from "./FAQ.module.css";
import RichText from "../../components-shared/RichText";
import { FAQ_itemsCollection_items } from "../../../__generated__/FAQ";

import { useWebTranslation } from "translations";
import { Button } from "ui-kit/Button";

type FAQProps = {
  items: (FAQ_itemsCollection_items | null)[];
};

const MAX_VISIBLE_ITEMS = 8;

export const FAQ: FC<FAQProps> = ({ items }) => {
  const { t } = useWebTranslation();
  const [expanded, setExpanded] = useState(false);

  const truthyItems = items.flatMap((item) => (item ? [item] : []));

  return (
    <div className={styles.wrapper}>
      <Accordion multiple collapsible>
        {truthyItems.map((item, index) => (
          <AccordionItem
            key={index}
            isHidden={expanded ? false : index >= MAX_VISIBLE_ITEMS}
          >
            <AccordionTitle>{item.question!}</AccordionTitle>
            <AccordionPanel>
              <RichText json={item?.answer?.json} assets={[]} />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      {truthyItems.length >= MAX_VISIBLE_ITEMS ? (
        <FlexCol alignItems="center">
          <Button
            variant="custom"
            color="white"
            textColor="blue300"
            onPress={() => {
              setExpanded(!expanded);
            }}
            title={t("faq.more_questions")}
            icon={expanded ? "chevron-up" : "chevron-down"}
          />
        </FlexCol>
      ) : null}
    </div>
  );
};
