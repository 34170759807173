import React, { ReactElement, useEffect, useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";

import { Button } from "../../../ui-kit/Button";
import { Typography } from "../../../ui-kit/Typography";
import Gutter from "../../../ui-kit/Gutter";

import { speciesToEmoji } from "../../../utils/insurance";
import { useWebTranslation } from "../../../translations";
import { logEvent } from "../../../analytics";

import styles from "./StandardHero.module.css";
import { replaceCommonMarkup } from "utils/utils";
import ContentfulMedia, { Asset } from "components-shared/ContentfulMedia";
import { Hero_c2A } from "../../../../__generated__/Hero";
import { ContentfulC2A } from "../ContentfulC2A";
import { getOrg } from "utils/site";
import { ReviewsSection } from "./ReviewsSection";
import { ReferralSection } from "./ReferralSection";
import { useResumePreviewStore } from "./useResumePreviewStore";

import dynamic from "next/dynamic";

const DynamicVideo = dynamic(() => import("./HeroVideo"), {
  ssr: false,
});

type HeroProps = {
  title: string | null;
  text?: string | null;
  usps?: string[];
  media: Asset;
  c2a?: Hero_c2A | null;
};

export default function Hero({
  title,
  text,
  usps,
  media,
  c2a,
}: HeroProps): ReactElement {
  const { t } = useWebTranslation();
  const router = useRouter();
  const signupState = useResumePreviewStore();

  return (
    <>
      <div className={styles.container}>
        <DynamicVideo />
        {/* <div style={{ background: "red", maxWidth: 1280, margin: "auto" }}> */}
        <div className={styles.imageBadge}>
          <Image
            src="/images/hero-avatars.png"
            alt=""
            width={82}
            height={30}
            className={styles.avatars}
          />
          <div className={styles.badgeText}>
            <Typography variant="h3">
              <strong>{t("hero.pets_number")}</strong>
            </Typography>
            <Typography variant="body2" style={{ lineHeight: 1 }}>
              {t("hero.pets_suffix")}
            </Typography>
          </div>
        </div>
        {/* </div> */}
        <div className={styles.content}>
          {/* <div className={styles.badge}>
            <div className={styles.badgeText}>
              <Typography variant="body3">
                <strong>{t("hero.pets_number")}</strong>
              </Typography>
              <Typography variant="body4" align="center">
                {t("hero.pets_suffix")}
              </Typography>
            </div>
          )}
          <ShowBlockOnMedia desktop mobile={false} tablet={false}>
            <ReviewsSection />
          </ShowBlockOnMedia>
          <div
            style={{
              maxHeight: "100vh",
            }}
          >
            <div className={styles.button}>
              {c2a ? (
                <span onClick={() => logEvent("USER_HOMEPAGE_GET_QUOTE")}>
                  <ContentfulC2A {...c2a} />
                </span>
              ) : null}
              {signupState?.previewId && (
                <>
                  <Gutter size={2} />
                  <div className={styles.resumeQuoteButton}>
                    <Button
                      textStyle={{
                        fontSize: 16,
                        fontFamily: "var(--font-eina-bold)",
                      }}
                      variant={"custom"}
                      color={"white"}
                      textColor="blue300"
                      title={
                        signupState.isMultipet
                          ? t("home.sections.btn_resume_quote_multipet")
                          : t("home.sections.0.btn_resume_quote", {
                              name: signupState?.name ?? "?",
                              emoji: speciesToEmoji(signupState.species),
                            })
                      }
                      onPress={() => {
                        const isLiability = signupState.isLiability;

            <Image
              src="/images/hero-avatars.png"
              alt=""
              width={67}
              height={30}
              className={styles.avatars}
            />
          </div> */}
          <div className={styles.textContent}>
            <div className={styles.title}>
              <Typography variant="h1">{title}</Typography>
            </div>
            {text && (
              <Typography variant="body2" className={styles.description}>
                {text}
              </Typography>
            )}
            {usps && (
              <div className={styles.usps}>
                <ul className={styles.uspList}>
                  {usps.map((usp) => (
                    <li className={styles.uspListItem} key={usp}>
                      <Typography color="black">
                        {replaceCommonMarkup(usp)}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </div>
            )}
            {/* <ShowBlockOnMedia desktop mobile={false} tablet={false}> */}
            <ReviewsSection />
            {/* </ShowBlockOnMedia> */}
            <div
              style={{
                maxHeight: "100vh",
              }}
            >
              <div className={styles.button}>
                {c2a ? (
                  <span onClick={() => logEvent("USER_HOMEPAGE_GET_QUOTE")}>
                    <ContentfulC2A {...c2a} />
                  </span>
                ) : null}
                {signupState?.previewId && (
                  <>
                    <Gutter size={2} />
                    <div className={styles.resumeQuoteButton}>
                      <Button
                        textStyle={{
                          fontSize: 16,
                          fontFamily: "var(--font-eina-bold)",
                        }}
                        variant={"custom"}
                        color={"white"}
                        textColor="blue300"
                        title={t("home.sections.0.btn_resume_quote", {
                          name: signupState?.name ?? "?",
                          emoji: speciesToEmoji(signupState.species),
                        })}
                        onPress={() => {
                          const isLiability = signupState.isLiability;

                          logEvent("USER_HOMEPAGE_RESUME_QUOTE");
                          router.push(
                            `/signup/${
                              isLiability
                                ? "liability-quote-preview"
                                : "quote-preview"
                            }?id=${signupState?.previewId}`
                          );
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReferralSection />
    </>
  );
}
