import React, { FC } from "react";
import { Typography } from "../../ui-kit/Typography";
import Link from "next/link";
import styles from "./Banner.module.css";

type BannerProps = {
  text: string;
  linkText: string;
  linkHref: string;
};

const Banner: FC<BannerProps> = ({ text, linkText, linkHref }) => {
  return (
    <>
      <div className={styles.container}>
        <Typography variant="body3">
          <strong>
            {text}{" "}
            <Link href={linkHref} style={{ textDecoration: "underline" }}>
              {linkText}
            </Link>
          </strong>
        </Typography>
      </div>
    </>
  );
};

export default Banner;
