import React, { FC } from "react";
import styles from "./LassieInMedia.module.css";
import { MediaCard } from "./MediaCard";
import { useWebTranslation } from "../../../../translations";
import { Typography } from "../../../../ui-kit/Typography";

type LassieInMediaProps = {};

export const LassieInMedia: FC<LassieInMediaProps> = () => {
  const { t } = useWebTranslation();

  const mediaCards = [
    {
      imageSrc: "/images/about-us/draft.png",
      text: t("about_us.media.sections.0.text"),
      date: t("about_us.media.sections.0.date"),
      url: "https://sifted.eu/articles/sweden-hottest-startups/",
    },
    {
      imageSrc: "/images/founders.webp",
      text: t("about_us.media.sections.1.text"),
      date: t("about_us.media.sections.1.date"),
      url: "https://www.di.se/digital/djur-techbolag-lanserar-forebyggande-forsakring-rider-pa-husdjurs-boomen/",
    },
    {
      imageSrc: "/images/Lassie-hemsida.png",
      text: t("about_us.media.sections.2.text"),
      date: t("about_us.media.sections.2.date"),
      url: "https://sakochliv.se/2021/06/11/jag-motte-lassie-ny-svensk-startup-vill-utmana-inom-djurforsakring/",
    },
  ];
  return (
    <section>
      <article className={styles.mediaArticle}>
        <Typography variant="h2">{t("about_us.media.title")}</Typography>
        <section className={styles.mediaItems}>
          {mediaCards.map((media) => (
            <MediaCard key={media.url} {...media} />
          ))}
        </section>
      </article>
    </section>
  );
};
