import { Glyph } from "ui-kit";

const icons: Record<string, Glyph> = {
  Paw: "paw",
  Clap: "clap",
  Flash: "lightning",
  Phone: "phone",
  Percent: "discount-badge",
  Knowledge: "graduation-cap",
  Star: "points",
  Scale: "scale",
  Cat: "cat",
  Dog: "dog",
  Headset: "headset",
  Firstaid: "first-aid",
  Calendar: "calendar",
  Trophy: "throphy",
  Document: "document",
};

export const getIcon = (icon?: string | null) =>
  icons[icon as keyof typeof icons] ?? icons.Paw;
