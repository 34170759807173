import { FC } from "react";
import { ContentfulComponentType } from "./types";
import { ContentfulArticleList } from "./ContentfulArticleList/ArticleList";
import { ContentfulColumnsBlock } from "./ContentfulColumnsBlock/ColumnsBlock";
import { ContentfulList } from "./ContentfulList/ContentfulList";
import { ContentfulPackages } from "./ContentfulPackages";
import { ContentfulButton } from "./ContentfulButton";
import { ContentfulComponent } from "./ContentfulComponent";
import { ContentfulGutter } from "./ContentfulGutter";
import { ContentfulFaq } from "./ContentfulFaq";
import { ContentfulFactBlock } from "./ContentfulFactBlock";
import { ContentfulFirstVetCard } from "./ContentfulFirstVetCard/FirstVetCard";
import { ContentfulHero } from "./ContentfulHero";
import { ContentfulTextAndMedia } from "./ContentfulTextAndMedia";
import { ContentfulRichText } from "./ContentfulRichText";
import { ContentfulC2A } from "./ContentfulC2A";
import { ContentfulTextAndList } from "./ContentfulTextAndList";
import { ContentfulCards } from "./ContentfulCards";
import { ContentfulBenefits } from "./ContentfulBenefits";
import { ContentfulTextAndText } from "./ContentfulTextAndText";

export enum ComponentTypeNames {
  Component = "Component",
  FirstVetCard = "ComponentFirstVetCard",
  FaqBlock = "ComponentFaq",
  Button = "ComponentButton",
  Cards = "ComponentCards",
  Hero = "ComponentHero",
  Benefits = "ComponentBenefits",
  Columns = "ComponentColumns",
  List = "ComponentList",
  Packages = "ComponentPackages",
  Gutter = "ComponentGutter",
  TextAndList = "ComponentTextAndList",
  TextAndMedia = "ComponentTextAndMedia",
  TextAndText = "ComponentTextAndText",
  ArticleList = "ComponentArticleList",
  FactBlock = "ComponentFactBlock",
  RichText = "ComponentRichText",
  C2A = "ComponentC2A",
}

export const ComponentMap: Record<
  string,
  ContentfulComponentType<any> | FC<any>
> = {
  [ComponentTypeNames.FirstVetCard]: ContentfulFirstVetCard,
  [ComponentTypeNames.FaqBlock]: ContentfulFaq,
  [ComponentTypeNames.Columns]: ContentfulColumnsBlock,
  [ComponentTypeNames.Component]: ContentfulComponent,
  [ComponentTypeNames.Cards]: ContentfulCards,
  [ComponentTypeNames.Hero]: ContentfulHero,
  [ComponentTypeNames.Benefits]: ContentfulBenefits,
  [ComponentTypeNames.TextAndList]: ContentfulTextAndList,
  [ComponentTypeNames.TextAndMedia]: ContentfulTextAndMedia,
  [ComponentTypeNames.TextAndText]: ContentfulTextAndText,
  [ComponentTypeNames.Packages]: ContentfulPackages,
  [ComponentTypeNames.ArticleList]: ContentfulArticleList,
  [ComponentTypeNames.FactBlock]: ContentfulFactBlock,
  [ComponentTypeNames.Button]: ContentfulButton,
  [ComponentTypeNames.RichText]: ContentfulRichText,
  [ComponentTypeNames.C2A]: ContentfulC2A,
};

export const RichTextMap: Record<
  string,
  ContentfulComponentType<any> | FC<any>
> = {
  [ComponentTypeNames.Button]: ContentfulButton,
  [ComponentTypeNames.Component]: ContentfulComponent,
  [ComponentTypeNames.Gutter]: ContentfulGutter,
  [ComponentTypeNames.List]: ContentfulList,
};
