import { Org, getOrg } from "utils/site";
import styles from "./InsuranceDocuments.module.css";
import {
  prepurchaseInfoInsurance,
  prepurchaseInfoLassie,
  termsAndConditions,
} from "components-shared/insuranceData";
import { Typography } from "ui-kit/Typography";
import { useWebTranslation } from "translations";
import IconButton from "components-shared/IconButton";
import { FlexCol } from "ui-kit/Layout/Flex";
import Gutter from "ui-kit/Gutter";
import { IconFrame } from "ui-kit";

const documents: Record<Org, Array<{ title: string; url: string }>> = {
  fra: [
    {
      title: "insurance_documents.ipid",
      url: "https://docs.lassie.co/fra/latest/ipid.pdf",
    },
    {
      title: "insurance_documents.terms_and_conditions",
      url: "https://docs.lassie.co/fra/latest/conditions-generales-de-lassurance-sante-animale-lassie.pdf",
    },
    {
      title: "insurance_documents.cgu",
      url: "https://docs.lassie.co/fra/latest/cgu.pdf",
    },
  ],
  ger: [
    {
      title: "quote_preview.germany.ipid.health",
      url: "https://docs.lassie.co/ger/de/ipid/latest/Produktubersicht-Krankenversicherung.pdf",
    },
    {
      title: "quote_preview.germany.ipid.op",
      url: "https://docs.lassie.co/ger/de/ipid/latest/Produktubersicht-OP-Versicherung.pdf",
    },
    {
      title: "quote_preview.germany.tac.health_and_op",
      url: "https://docs.lassie.co/ger/de/terms-and-conditions/latest/Allgemeine-Versicherungsbedingungen-OP-und-Krankenversicherung.pdf",
    },
  ],
  swe: [
    {
      title: "quote_preview_desktop.important.links.0.description",
      url: termsAndConditions,
    },
    {
      title: "quote_preview_desktop.important.links.1.description",
      url: prepurchaseInfoInsurance,
    },
    {
      title: "quote_preview_desktop.important.links.2.description",
      url: prepurchaseInfoLassie,
    },
  ],
};

const InsuranceDocuments = () => {
  const { t } = useWebTranslation();

  return (
    <div className={styles.container}>
      {documents[getOrg()].map((document) => (
        <FlexCol
          key={document.title}
          className={styles.document}
          alignItems="center"
        >
          <IconFrame icon="document" iconSize="M" />
          <Gutter size={2} />
          <Typography variant="h4" align="center">
            {t(document.title)}
          </Typography>
          <Gutter size={2} />
          <div style={{ marginTop: "auto" }} />
          <IconButton
            title={t("global.read_more")}
            backgroundColor="navy100"
            onPress={() => window.open(document.url, "_blank")}
            color="blue300"
            icon="chevron-right"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          />
        </FlexCol>
      ))}
    </div>
  );
};

export default InsuranceDocuments;
