/* eslint-disable react/display-name */
import React, { ReactElement } from "react";
import { BLOCKS, Document, INLINES, MARKS } from "@contentful/rich-text-types";
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import ContentfulMedia from "./ContentfulMedia";
import styles from "./RichText.module.css";
import Anchor from "./Anchor";
import { Typography } from "../ui-kit/Typography";
import { ContentfulRichTextRenderer } from "../components/ContentfulRenderer";
import { getSiteUrl } from "../utils/site";
import Gutter from "../ui-kit/Gutter";

type Asset = {
  sys: { id: string };
  url?: string | null;
  contentType?: string | null;
  description: string | null;
  width?: number | null;
  height?: number | null;
} | null;

type HyperLink = { sys?: { id: string }; uri?: string | null } | null;

const createOptions = ({
  assets,
  hyperlinks,
  inlineEntries,
}: {
  assets: Asset[];
  hyperlinks: HyperLink[];
  inlineEntries?: Array<any>;
}): Options => ({
  renderMark: {
    [MARKS.BOLD]: (text) => <strong>{text}</strong>,
    [MARKS.ITALIC]: (text) => <span className={styles.italic}>{text}</span>,
    [MARKS.UNDERLINE]: (text) => (
      <span className={styles.underline}>{text}</span>
    ),
    [MARKS.CODE]: (text) => {
      if ((text as ReactElement)?.props?.children) {
        return (
          <span
            dangerouslySetInnerHTML={{
              __html: (text as ReactElement)?.props?.children,
            }}
          />
        );
      }
      if (
        (text as string)?.endsWith &&
        (text as string)?.endsWith("</iframe>")
      ) {
        return <span dangerouslySetInnerHTML={{ __html: text as string }} />;
      }
      return <span>{text}</span>;
    },
  },
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node?.data?.uri) {
        return <Anchor href={node.data.uri}>{children}</Anchor>;
      }
    },
    [BLOCKS.DOCUMENT]: (node, children) => (
      <div className={styles.document}>{children}</div>
    ),
    [BLOCKS.HEADING_1]: (node, children) => (
      <>
        <Typography variant="h1">{children}</Typography>
      </>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <Typography variant="h2">{children}</Typography>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <Typography variant="h3">{children}</Typography>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <Typography variant="h4">{children}</Typography>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <Typography variant="h5">{children}</Typography>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <Typography variant="h6">{children}</Typography>
    ),
    [BLOCKS.UL_LIST]: (node, children) => {
      return <ul className={styles.ul}>{children}</ul>;
    },
    [BLOCKS.OL_LIST]: (node, children) => {
      return <ol className={styles.ul}>{children}</ol>;
    },
    [BLOCKS.LIST_ITEM]: (node, children) => {
      return <li className={styles.li}>{children}</li>;
    },
    [BLOCKS.QUOTE]: (node, children) => (
      <div className={styles.quote}>{children}</div>
    ),
    [BLOCKS.HR]: () => <div className={styles.hr} />,

    [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
      return <div>{children}</div>;
    },
    "entry-hyperlink": (node, children) => {
      const uri = hyperlinks.find(
        (h) => h?.sys?.id === node.data.target.sys.id
      )?.uri;
      const hrefUrl = uri ? uri : getSiteUrl("app.html");
      return <Anchor href={hrefUrl}>{children}</Anchor>;
    },

    [BLOCKS.EMBEDDED_ASSET]: (node) => {
      const { data } = node;
      const asset = assets.find((a) => data.target.sys.id === a?.sys.id);

      if (asset) {
        return (
          <div
            style={{
              borderRadius: "26px",
              overflow: "hidden",
            }}
          >
            <ContentfulMedia media={asset} />
          </div>
        );
      }
      return <div />;
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      const content = node.content.filter(
        (c) => c.nodeType !== "text" || (c.nodeType === "text" && c.value)
      );
      const hasContent = !!content.length;
      const hasTextContent = content.some((c) => c.nodeType === "text");

      return hasContent ? (
        <Typography component={hasTextContent ? "p" : "span"} color="grey500">
          {children}
        </Typography>
      ) : (
        <Gutter size={2} />
      );
    },
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const entryId = node.data.target.sys.id;
      const entry = inlineEntries?.find((entry) => entry?.sys?.id === entryId);

      if (!entry) {
        return null;
      }
      return entry ? <ContentfulRichTextRenderer component={entry} /> : null;
    },
  },
  renderText: (text) => {
    return text.split("\n").reduce<any[]>((children, textSegment, index) => {
      return [...children, index > 0 && <br key={index} />, textSegment];
    }, []);
  },
});

const RichText = ({
  json,
  assets,
  hyperlinks = [],
  inlineEntries = [],
}: {
  json: Document;
  assets: Asset[];
  hyperlinks?: HyperLink[];
  inlineEntries?: Array<any>;
}): ReactElement => {
  return json ? (
    <>
      {documentToReactComponents(
        json,
        createOptions({
          assets,
          hyperlinks,
          inlineEntries,
        })
      )}
    </>
  ) : (
    <div />
  );
};

RichText.defaultProps = {
  hyperlinks: [],
};

export default RichText;
