import React, { useState } from "react";
import { ContentfulComponentType } from "../types";
import { gql } from "@apollo/client";
import CompactPackagesSection from "./CompactPackages/CompactPackagesSection";
import MobileFullPackages from "./MobileFullPackages/MobileFullPackages";
import InsurancePackagesTable from "./InsurancePackagesTable/InsurancePackagesTable";
import { ProductEnum } from "../../../../__generated__/globalTypes";
import ShowOnMedia from "../../../ui-kit/ShowOnMedia";
import { Packages } from "../../../../__generated__/Packages";
import { getPackageConfig } from "./getPackageConfig";
import { PackageConfig } from "./types";
import Tabs from "ui-kit/Tabs/Tabs";
import { useWebTranslation } from "translations";
import Gutter from "ui-kit/Gutter";
import TextFields from "../TextFields";
import { ContentfulC2A } from "../ContentfulC2A";
import { FlexCol } from "ui-kit/Layout/Flex";
import "swiper/css";

const covertProductsToConfigs = (products: (string | null)[] | null) =>
  (products ?? [])
    .map((product) =>
      getPackageConfig({
        product: product?.toLowerCase() as ProductEnum,
      })
    )
    .filter((config) => config !== null) as PackageConfig[];

export const ContentfulPackages: ContentfulComponentType<Packages> = ({
  products,
  layout,
  highlight,
  title,
  text,
  c2A,
}) => {
  const { t } = useWebTranslation();
  const [configIndex, setConfigIndex] = useState<number>(0);
  const configs = covertProductsToConfigs(products);
  const config = configs[configIndex];
  const isCompact = !!layout;

  if (!configs.length) {
    return null;
  }

  return (
    <>
      <TextFields highlight={highlight} title={title} text={text} center />
      {!isCompact && configs.length > 1 && (
        <>
          <div style={{ maxWidth: 420, width: "100%", margin: "auto" }}>
            <Tabs
              items={[]}
              tabs={configs.map((config) => {
                if (config.product === ProductEnum.dog) {
                  return {
                    icon: "dog",
                    value: ProductEnum.dog,
                    label: t("global.dog"),
                  };
                }
                if (config.product === ProductEnum.cat) {
                  return {
                    icon: "cat",
                    value: ProductEnum.cat,
                    label: t("global.cat"),
                  };
                }

                return {
                  icon: "dog",
                  value: ProductEnum.liability,
                  label: t("global.liability"),
                };
              })}
              onChangeTabs={(_, index) => setConfigIndex(index)}
              index={configIndex}
            />
          </div>
          <Gutter size={3} />
        </>
      )}
      {isCompact ? (
        <CompactPackagesSection config={config} initialIndex={1} />
      ) : (
        <>
          <ShowOnMedia desktop={false} tablet={true} mobile={true}>
            <MobileFullPackages config={config} />
          </ShowOnMedia>
          <ShowOnMedia desktop={true} tablet={false} mobile={false}>
            <InsurancePackagesTable config={config} />
          </ShowOnMedia>
        </>
      )}
      {c2A && (
        <FlexCol alignItems="center" justifyContent="center">
          <Gutter size={3} />
          <ContentfulC2A {...c2A} />
        </FlexCol>
      )}
    </>
  );
};

ContentfulPackages.fragment = gql`
  fragment Packages on ComponentPackages {
    products
    layout
    highlight
    title
    text
    c2A {
      ...C2A
    }
    sys {
      id
      publishedAt
    }
  }
`;
