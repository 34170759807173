import { Icon, IconFrame } from "@/ui-kit";
import { Typography } from "ui-kit/Typography";
import { ListStyleEnum } from ".";
import { getIcon } from "../icons";
import Colors from "ui-kit/colors";
import styles from "./List.module.css";

const ListStyle = ({
  listStyle,
  icon,
  index,
}: {
  listStyle?: ListStyleEnum;
  icon?: string | null;
  index: number;
}) => {
  if (listStyle === ListStyleEnum.Bullet) {
    return (
      <Typography variant="body1" color="navy300" className={styles.listStyle}>
        •
      </Typography>
    );
  }

  if (listStyle === ListStyleEnum.Check) {
    return (
      <Icon
        name="checkmark"
        size="S"
        color={Colors.green200}
        className={styles.listStyleCheck}
      />
    );
  }

  if (listStyle === ListStyleEnum.Number) {
    return (
      <div className={styles.listStyleNumber}>
        <Typography variant="body3">
          <strong>{index + 1}</strong>
        </Typography>
      </div>
    );
  }

  if (listStyle === ListStyleEnum.Icon) {
    const iconName = getIcon(icon);

    return (
      <IconFrame
        icon={iconName}
        iconColor={Colors.blue300}
        style="round"
        className={styles.listStyle}
      />
    );
  }

  return <div />;
};

export default ListStyle;
