import React, { FC } from "react";
import Colors from "../colors";
import { Typography } from "../Typography";
import Icon from "../Icon";
import Gutter, { GutterSizes } from "../Gutter";
import { FlexRow } from "../Layout/Flex";
import styles from "./Pill.module.css";
import { TypographyVariant } from "../Typography/types";

interface PillProps {
  color: keyof typeof Colors;
  backgroundColor: keyof typeof Colors;
  text: string;
  icon?: string;
  iconFirst?: boolean;
  iconSize?: number;
  alignSelf?: "flex-start" | "flex-end" | "center";
  dashed?: boolean;
  gutter?: GutterSizes;
  textVariant?: keyof typeof TypographyVariant;
  onPress?: () => void;
  borderColor?: keyof typeof Colors;
}

export const Pill: FC<PillProps> = ({
  color,
  text,
  backgroundColor,
  icon,
  iconFirst,
  iconSize = 16,
  alignSelf,
  dashed,
  textVariant,
  gutter,
  onPress,
  borderColor,
}) => {
  return (
    <div onClick={onPress ? () => onPress() : undefined}>
      <FlexRow
        reverse={iconFirst}
        justifyContent="center"
        alignItems="center"
        className={styles.pill}
        style={{
          backgroundColor: dashed ? undefined : Colors[backgroundColor],
          alignSelf,
          borderColor: borderColor ? Colors[borderColor] : undefined,
          borderWidth: borderColor ? 2 : undefined,
        }}
      >
        {!!gutter && <Gutter size={gutter} horizontal />}
        <Typography variant={textVariant || "body4"} color={color}>
          <strong>{text}</strong>
        </Typography>
        {!!gutter && <Gutter size={gutter} horizontal />}
        {!!icon && (
          <>
            <Gutter size={0.5} horizontal />
            <Icon name={icon} size={iconSize} color={Colors[color]} />
          </>
        )}
      </FlexRow>
    </div>
  );
};
Pill.defaultProps = {
  icon: "",
  iconFirst: false,
  alignSelf: "flex-start",
  dashed: false,
  gutter: 0,
  onPress: undefined,
  borderColor: undefined,
  iconSize: 16,
};
