import { ContentfulComponentType } from "../types";
import { gql } from "@apollo/client";
import React from "react";
import { FAQ } from "../../FAQ/index";
import { FAQ as FAQProps } from "../../../../__generated__/FAQ";
import { FaqItemCollection_faqItemCollection_items } from "../../../../__generated__/FaqItemCollection";
import { useWebTranslation } from "translations";
import TextFields from "../TextFields";
import Gutter from "ui-kit/Gutter";
import { ContentfulC2A } from "../ContentfulC2A";
import { FlexCol } from "ui-kit/Layout/Flex";

export const ContentfulFaq: ContentfulComponentType<
  FAQProps & {
    tagItems: (FaqItemCollection_faqItemCollection_items | null)[];
  }
> = ({ highlight, title, text, itemsCollection, tagItems, c2A }) => {
  const items = itemsCollection?.items ?? [];
  const { t } = useWebTranslation();

  if (items.length) {
    return (
      <>
        <TextFields
          highlight={highlight}
          title={title}
          text={text}
          gutterBottom={0}
          center
        />
        {c2A && (
          <FlexCol alignItems="center" justifyContent="center">
            <Gutter size={3} />
            <ContentfulC2A {...c2A} />
          </FlexCol>
        )}
        {title && <Gutter size={5} />}
        <FAQ items={items} />
      </>
    );
  }

  if (tagItems.length) {
    const [firstQuestion] = tagItems;
    const [firstTag] = firstQuestion?.contentfulMetadata?.tags ?? [];

    if (!title) {
      title = t(firstTag?.id ? `faq_title.${firstTag.id}` : "faq.header.title");
    }

    return (
      <>
        <TextFields highlight={highlight} title={title} text={text} center />
        {c2A && (
          <FlexCol alignItems="center" justifyContent="center">
            <Gutter size={3} />
            <ContentfulC2A {...c2A} />
          </FlexCol>
        )}
        <FAQ items={tagItems} />
      </>
    );
  }

  return null;
};

ContentfulFaq.fragment = gql`
  fragment FAQ on ComponentFaq {
    highlight
    title
    text
    itemsCollection(limit: 20) {
      items {
        sys {
          id
        }
        question
        answer {
          json
        }
      }
    }
    tagId
    c2A {
      ...C2A
    }
    sys {
      id
      publishedAt
    }
  }
`;
