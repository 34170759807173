import React, { FC } from "react";
import { Typography } from "../../../../ui-kit/Typography";
import { useWebTranslation } from "../../../../translations";
import ShowOnMedia from "../../../../ui-kit/ShowOnMedia";
import Gutter from "../../../../ui-kit/Gutter";
import styles from "./AppLevelsAndDiscountsCards.module.css";
import Image from "next/image";
import DogImage from "../../../../../public/images/lassieapp/dog.jpeg";
import CutImage from "../../../../../public/images/lassieapp/cat.png";
import ResponsiveGutter from "../../../../ui-kit/ResponsiveGutter";

type AppLevelsAndDiscountsCardsProps = {};

export const AppLevelsAndDiscountsCards: FC<
  AppLevelsAndDiscountsCardsProps
> = () => {
  const { t } = useWebTranslation();
  return (
    <div>
      <ResponsiveGutter desktop={9} mobile={6.5} tablet={6.5} />
      <ShowOnMedia mobile={false} tablet={false}>
        <Typography component={"div"} variant="h2">
          {t("lassie_app.discount.title")}
        </Typography>
        <Gutter size={8} />
      </ShowOnMedia>
      <div className={styles.container}>
        <div className={styles.card}>
          <div className={styles.image}>
            <Image
              width={486}
              height={251}
              alt={"Dog"}
              src={DogImage}
              objectFit={"cover"}
              layout={"responsive"}
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </div>
          <div className={styles.textContainer}>
            <Gutter size={2} />
            <Typography variant="h2">
              {`🐶 ${t("lassie_app.discount.dog.title")}`}
            </Typography>
            <Gutter size={2} />
            <Typography component={"div"}>
              {t("lassie_app.discount.dog.rows.0")}
            </Typography>
            <Typography component={"div"}>
              {t("lassie_app.discount.dog.rows.1")}
            </Typography>
            <Typography component={"div"}>
              {t("lassie_app.discount.dog.rows.2")}
            </Typography>
            <Gutter size={2} />
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.image}>
            <Image
              width={486}
              height={251}
              src={CutImage}
              layout={"responsive"}
              alt={"Cat"}
              objectFit={"cover"}
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </div>
          <div className={styles.textContainer}>
            <Gutter size={2} />
            <Typography variant="h2">
              {`🐱 ${t("lassie_app.discount.cat.title")}`}
            </Typography>
            <Gutter size={2} />
            <Typography component={"div"}>
              {t("lassie_app.discount.cat.rows.0")}
            </Typography>
            <Typography component={"div"}>
              {t("lassie_app.discount.cat.rows.1")}
            </Typography>
            <Typography component={"div"}>
              {t("lassie_app.discount.cat.rows.2")}
            </Typography>
            <Gutter size={2} />
          </div>
        </div>
      </div>
    </div>
  );
};
