import React from "react";
import { gql } from "@apollo/client";
import Image from "next/image";
import styles from "./ContentfulMedia.module.css";
import classNames from "classnames";

export type Asset = {
  title?: string | null;
  description?: string | null;
  url?: string | null;
  height?: number | null;
  width?: number | null;
  contentType?: string | null;
} | null;

type ContentfulMediaProps = {
  media: Asset;
  thumbnail?: Asset;
  sizes?: HTMLImageElement["sizes"];
  display?: "square" | "round" | "header";
};

const ContentfulMedia = ({
  media,
  thumbnail,
  display,
  sizes = "1200px",
}: ContentfulMediaProps) => {
  if (!media?.url) {
    return null;
  }

  const isVideo = media.contentType?.includes("video");

  return isVideo ? (
    <>
      {thumbnail?.url && (
        <Image
          src={thumbnail.url}
          alt={thumbnail.title ?? thumbnail.description ?? ""}
          width={thumbnail.width ?? 1200}
          height={thumbnail.height ?? 1200}
          sizes={sizes}
          className={classNames(styles.media, display && styles[display])}
          style={{ position: "absolute" }}
        />
      )}
      <video
        key={media.url}
        autoPlay
        muted
        loop
        playsInline
        controls={false}
        className={classNames(styles.media, display && styles[display])}
        style={thumbnail?.url ? { zIndex: 1 } : {}}
      >
        <source src={media.url} type={media.contentType || "video/mp4"} />
      </video>
    </>
  ) : (
    <Image
      src={media.url}
      alt={media.title ?? media.description ?? ""}
      width={media.width ?? 1200}
      height={media.height ?? 1200}
      sizes={sizes}
      className={classNames(styles.media, display && styles[display])}
    />
  );
};

ContentfulMedia.fragment = gql`
  fragment MediaAsset on Asset {
    sys {
      id
    }
    contentType
    url
    description
  }
`;

export default ContentfulMedia;
