import React, { FC, useState } from "react";
import { ContentfulComponentType } from "../types";
import { gql } from "@apollo/client";
import styles from "./ColumnsBlock.module.css";
import {
  Columns,
  Columns_columnsCollection_items,
  Columns_columnsCollection_items_richText,
} from "../../../../__generated__/Columns";
import RichText from "../../../components-shared/RichText";
import classNames from "classnames";
import ReactPlayer from "react-player";
import TextFields from "../TextFields";
import Gutter from "ui-kit/Gutter";

const getDisplayClassname = (display: string) => {
  switch (display) {
    case "card":
      return styles.card;
    case "smallCard":
      return styles.smallCard;
    case "bigPadding":
      return styles.bigPadding;
    case "framePadding":
      return styles.framePadding;
    case "smallPadding":
      return styles.smallPadding;
    case "video":
      return styles.video;
    case "receipt":
      return styles.receipt;
    case "default":
    default:
      return "";
  }
};

const getHorizontalAlignment = (horizontalAlignment: string) => {
  switch (horizontalAlignment) {
    case "center":
      return styles.horizontalCenter;
    case "right":
      return styles.horizontalRight;
    case "left":
    default:
      return styles.horizontalLeft;
  }
};

const getVerticalAlignment = (verticalAlignment: string) => {
  switch (verticalAlignment) {
    case "center":
      return styles.verticalCenter;
    case "bottom":
      return styles.verticalBottom;
    case "space-between":
      return styles.verticalSpaceBetween;
    case "top":
    default:
      return styles.verticalTop;
  }
};

const getImageFit = (imageFit: string) => {
  switch (imageFit) {
    case "contain":
      return styles.imageContain;
    case "cover":
    default:
      return "";
  }
};

const VideoFrameColumn: FC<{
  richText: Columns_columnsCollection_items_richText | null;
}> = ({ richText }) => {
  const assetId = richText?.json?.content[0]?.data?.target?.sys?.id;
  const asset = richText?.links.assets.block.find((a) => a?.sys.id === assetId);
  const [isPlay, setPlay] = useState<boolean>(false);
  if (!asset?.url) {
    return null;
  }
  const isImage = asset?.contentType?.includes("image");

  return (
    <div className={styles.framePadding}>
      <div className={styles.iphoneFrame}>
        <div className={styles.iphoneFrameInner}>
          {!isPlay && !isImage && (
            <img
              onClick={() => setPlay(true)}
              className={styles.playBtn}
              src="/images/lassieapp/play.png"
            />
          )}
          <img
            className={styles.iphoneFrameImage}
            src="/images/lassieapp/IphoneFrame.png"
            loading="lazy"
          />
          {isImage ? (
            <img
              style={{ objectFit: "cover" }}
              width={asset.width ?? 0}
              height={asset.height ?? 0}
              src={asset?.url}
              alt={asset?.description ?? ""}
            />
          ) : (
            <ReactPlayer
              key={asset?.url}
              playsinline
              playing={isPlay}
              muted
              onPause={() => setPlay(false)}
              controls={false}
              url={[{ src: asset?.url, type: "video/mp4" }]}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const Column: FC<Columns_columnsCollection_items> = ({
  display,
  flex,
  richText,
  horizontalAlignment,
  verticalAlignment,
  imageFit,
  maxWidth,
}) => {
  if (display === "mobileFrame") {
    return <VideoFrameColumn richText={richText} />;
  }
  return (
    <div
      style={{
        flex: flex || 1,
        maxWidth: maxWidth || undefined,
        marginLeft: maxWidth ? "auto" : undefined,
        marginRight: maxWidth ? "auto" : undefined,
      }}
      className={classNames(styles.item, getDisplayClassname(display!))}
    >
      <div
        className={classNames(
          styles.innerItemContainer,
          getHorizontalAlignment(horizontalAlignment!),
          getVerticalAlignment(verticalAlignment!),
          getImageFit(imageFit!)
        )}
      >
        <RichText
          json={richText?.json}
          assets={richText?.links.assets.block ?? []}
          inlineEntries={richText?.links.entries.inline ?? []}
        />
      </div>
    </div>
  );
};

export const ContentfulColumnsBlock: ContentfulComponentType<Columns> = ({
  title,
  text,
  backgroundColor,
  columnsCollection,
  withBorder,
}) => {
  return (
    <>
      <TextFields title={title} text={text} center />
      <div
        className={classNames(
          styles.container,
          withBorder && styles.withBorder
        )}
        style={{ backgroundColor: backgroundColor || "unset" }}
      >
        {columnsCollection?.items.map((item, index) => (
          <Column key={index} {...item!} />
        ))}
      </div>
    </>
  );
};

ContentfulColumnsBlock.fragment = gql`
  fragment Columns on ComponentColumns {
    title
    text
    backgroundColor
    withBorder
    columnsCollection(limit: 4) {
      items {
        richText {
          json
          links {
            entries {
              inline {
                ...Button
                ...List
                ...Component
                ...Gutter
              }
            }
            assets {
              block {
                sys {
                  id
                }
                url
                contentType
                description
                width
                height
              }
            }
          }
        }
        flex
        display
        verticalAlignment
        horizontalAlignment
        imageFit
        maxWidth
      }
    }
    sys {
      id
      publishedAt
    }
  }
`;
