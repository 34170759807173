import Script from "next/script";

const ELEMENT_ID = "calendlyWidget";

export const CalendlyWidget = ({ url }: { url: string }) => {
  return (
    <>
      <div
        className="calendly-inline-widget"
        data-url={url}
        style={{ width: "100%", height: 800 }}
      ></div>
      <Script
        strategy="afterInteractive"
        src="https://assets.calendly.com/assets/external/widget.js"
      />
    </>
  );
};
