import React from "react";
import { Typography } from "../../../ui-kit/Typography";
import styles from "./index.module.css";
import Gutter from "../../../ui-kit/Gutter";
import ResponsiveGutter from "../../../ui-kit/ResponsiveGutter";
import { ContentfulComponentType } from "../types";
import { gql } from "@apollo/client";
import { ContentfulButton } from "../ContentfulButton";
import { FactBlock_button } from "../../../../__generated__/FactBlock";

type Props = {
  title: string;
  text: string;
  button: FactBlock_button;
};

export const ContentfulFactBlock: ContentfulComponentType<Props> = (props) => {
  return (
    <>
      <ResponsiveGutter mobile={2} tablet={2} desktop={4} />
      <div className={styles.row}>
        <Typography color="blue300" variant="h4">
          {props.title}
        </Typography>
        <Gutter size={3} />
        <Typography color="grey500">{props.text}</Typography>
        <Gutter size={4} />
        <ContentfulButton {...props.button} />
      </div>
    </>
  );
};

ContentfulFactBlock.fragment = gql`
  fragment FactBlock on ComponentFactBlock {
    title
    text
    sys {
      id
      publishedAt
    }
    button {
      ...Button
    }
  }
`;
