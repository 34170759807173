import React, { FC, ReactNode } from "react";
import styles from "./CompactInfoSection.module.css";
import classNames from "classnames";

type Props = {
  children: ReactNode;
  alignItems?: "center" | "flex-start";
  mobileDirection?: "default" | "reverse";
  mobileCard?: boolean;
};

export const CompactInfoSection: FC<Props> = ({
  children,
  alignItems = "center",
  mobileDirection = "default",
  mobileCard,
}) => {
  return (
    <section
      style={{ alignItems }}
      className={classNames(
        styles.container,
        mobileDirection === "reverse" && styles.mobileReverse,
        mobileCard && styles.mobileCard
      )}
    >
      {children}
    </section>
  );
};
