import React from "react";
import { ContentfulComponentType } from "../types";
import { gql } from "@apollo/client";
import { Button as ButtonType } from "../../../../__generated__/Button";
import { Button } from "../../../ui-kit/Button";
import { useRouter } from "next/router";
import { ButtonVariants } from "../../../ui-kit/Button/Button";
import Colors from "../../../ui-kit/colors";

const getHorizontalAlignment = (horizontalAlignment: string) => {
  switch (horizontalAlignment) {
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    case "center":
    default:
      return "center";
  }
};

const getButtonVariantProps = (variant: string | null) => {
  if (variant === "disabled") {
    return {
      variant: ButtonVariants.default,
      disabled: true,
    };
  }

  if (variant === "signup") {
    return {
      variant: ButtonVariants.custom,
      color: "green200" as keyof typeof Colors,
    };
  }

  return { variant: variant as ButtonVariants };
};

export const ContentfulButton: ContentfulComponentType<ButtonType> = ({
  title,
  url,
  isDownloadLink,
  horizontalAlignment,
  variant,
  pageLink,
}) => {
  const router = useRouter();

  const onPress = () => {
    if (pageLink) {
      router.push(pageLink.slug || "");
      return;
    }
    if (!isDownloadLink) {
      router.push(url!);
      return;
    }
    const a = document.createElement("a");

    a.href = url ?? "";
    a.download = url?.split("/").pop() ?? "";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: getHorizontalAlignment(horizontalAlignment || ""),
      }}
    >
      <div style={{ width: "auto", minWidth: 176 }}>
        <Button
          title={title!}
          onPress={onPress}
          {...getButtonVariantProps(variant)}
        />
      </div>
    </div>
  );
};

ContentfulButton.fragment = gql`
  fragment Button on ComponentButton {
    title
    url
    isDownloadLink
    horizontalAlignment
    variant
    pageLink {
      slug
    }
    sys {
      id
    }
  }
`;
