import React, { FC } from "react";
import { gql } from "@apollo/client";
import { Benefits as BenefitsProps } from "../../../../__generated__/Benefits";
import { ContentfulComponentType } from "../types";
import styles from "./ContentfulBenefits.module.css";
import TextFields from "../TextFields";
import Gutter from "ui-kit/Gutter";
import { ContentfulC2A } from "../ContentfulC2A";
import { FlexCol, FlexRow } from "ui-kit/Layout/Flex";
import { getIcon } from "../icons";
import { Typography } from "ui-kit/Typography";
import classNames from "classnames";
import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionTitle,
  IconFrame,
} from "ui-kit";

type BenefitItem = {
  title: string | null;
  text: string | null;
  icon: string | null;
};

const SideBySideBenefitItem: FC<{ benefit: BenefitItem }> = ({ benefit }) => {
  const { title, text, icon } = benefit;
  const iconName = getIcon(icon);

  return (
    <FlexRow key={title} alignItems="center">
      <IconFrame icon={iconName} iconSize="M" style="round" />
      <Gutter size={2} horizontal />
      <FlexCol>
        <Typography variant="body1">
          <strong>{title}</strong>
        </Typography>
        <Gutter size={0.5} />
        <Typography>{text}</Typography>
      </FlexCol>
    </FlexRow>
  );
};

const OverUnderBenefitItem: FC<{ benefit: BenefitItem }> = ({ benefit }) => {
  const { title, text, icon } = benefit;
  const iconName = getIcon(icon);

  return (
    <FlexCol key={title} alignItems="center">
      <IconFrame icon={iconName} iconSize="M" style="round" />
      <Gutter size={2} />
      <FlexCol style={{ textAlign: "center" }}>
        <Typography variant="body1">
          <strong>{title}</strong>
        </Typography>
        <Gutter size={0.5} />
        <Typography>{text}</Typography>
      </FlexCol>
    </FlexCol>
  );
};

export const ContentfulBenefits: ContentfulComponentType<BenefitsProps> = ({
  highlight,
  title,
  text,
  benefitItemsCollection,
  benefitLayout,
  c2A,
  listLayout,
}) => {
  const benefitItems =
    benefitItemsCollection?.items.flatMap((item) => (item ? [item] : [])) ?? [];

  return (
    <>
      <TextFields highlight={highlight} title={title} text={text} center />

      {listLayout ? (
        <Accordion collapsible index={undefined} className={styles.accordion}>
          <div>
            {benefitItems
              .slice(0, Math.ceil(benefitItems.length / 2))
              .map((benefit) => {
                return (
                  <AccordionItem
                    key={benefit.title}
                    className={styles.accordionItemWrapper}
                  >
                    <div className={styles.accordionItem}>
                      <AccordionTitle icon={getIcon(benefit.icon)}>
                        {benefit.title}
                      </AccordionTitle>
                      <AccordionPanel>
                        <div className={styles.accordionText}>
                          {benefit.text}
                        </div>
                      </AccordionPanel>
                    </div>
                  </AccordionItem>
                );
              })}
          </div>
          <div>
            {benefitItems
              .slice(Math.floor(benefitItems.length / 2))
              .map((benefit) => {
                return (
                  <AccordionItem
                    key={benefit.title}
                    className={styles.accordionItemWrapper}
                  >
                    <div className={styles.accordionItem}>
                      <AccordionTitle
                        icon={getIcon(benefit.icon)}
                        className={styles.accordionTitle}
                      >
                        {benefit.title}
                      </AccordionTitle>
                      <AccordionPanel>
                        <div className={styles.accordionText}>
                          {benefit.text}
                        </div>
                      </AccordionPanel>
                    </div>
                  </AccordionItem>
                );
              })}
          </div>
        </Accordion>
      ) : (
        <div
          className={classNames(
            styles.benefits,
            benefitLayout && styles.sideBySide
          )}
        >
          {benefitItems.map((benefit) =>
            benefitLayout ? (
              <SideBySideBenefitItem key={benefit.title} benefit={benefit} />
            ) : (
              <OverUnderBenefitItem key={benefit.title} benefit={benefit} />
            )
          )}
        </div>
      )}
      {c2A && (
        <FlexCol alignItems="center" justifyContent="center">
          <Gutter size={4} />
          <ContentfulC2A {...c2A} />
        </FlexCol>
      )}
    </>
  );
};

ContentfulBenefits.fragment = gql`
  fragment Benefits on ComponentBenefits {
    highlight
    title
    text
    benefitItemsCollection(limit: 12) {
      items {
        title
        text
        icon
      }
    }
    benefitLayout
    listLayout
    c2A {
      ...C2A
    }
    sys {
      id
      publishedAt
    }
  }
`;
