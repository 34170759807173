import React, { FC } from "react";
import Image from "next/image";
import styles from "./ClaimsPageEmailBlock.module.css";
import { Typography } from "../../../../ui-kit/Typography";
import { useWebTranslation } from "../../../../translations";
import classNames from "classnames";
import ShowOnMedia from "../../../../ui-kit/ShowOnMedia";
import ResponsiveGutter from "../../../../ui-kit/ResponsiveGutter";
import Gutter from "../../../../ui-kit/Gutter";

type ClaimsPageEmailBlockProps = {};

export const ClaimsPageEmailBlock: FC<ClaimsPageEmailBlockProps> = () => {
  const { t } = useWebTranslation();
  const items = [
    t("claim.send_email.checkmarks.0"),
    t("claim.send_email.checkmarks.1"),
    t("claim.send_email.checkmarks.2"),
    t("claim.send_email.checkmarks.3"),
    t("claim.send_email.checkmarks.4"),
    t("claim.send_email.checkmarks.5"),
  ];
  return (
    <section className={styles.container}>
      <div className={styles.item} style={{ paddingTop: "16px" }}>
        <Typography color={"white"} component={"div"} variant="h3">
          {t("claim.send_email.title")}
        </Typography>
        <Gutter size={2} />
        <div className={styles.mobileMargin}>
          <Typography color={"white"} component={"div"}>
            {t("claim.send_email.note")}
          </Typography>
        </div>
        <ShowOnMedia desktop={true} tablet={false} mobile={false}>
          <div style={{ height: "32px" }} />
          <Typography color={"white"} component={"div"}>
            {t("claim.send_email.text")}
          </Typography>
        </ShowOnMedia>
      </div>
      <div className={styles.arrowRight}>
        <Image
          src="/images/claim/icon-arrow-right.svg"
          width={22}
          height={22}
          alt="Arrow right"
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </div>
      <div className={styles.arrowDown}>
        <Image
          src="/images/claim/icon-arrow-down.svg"
          width={22}
          height={22}
          alt="Arrow down"
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </div>
      <div className={classNames(styles.item, styles.checkItemsContainer)}>
        {items.map((item, index) => (
          <div key={index} className={styles.checkItem}>
            <span style={{ marginRight: "8px" }}>✔</span>
            <Typography color={"blue300"} variant="h6" component="span">
              {item}
            </Typography>
          </div>
        ))}
      </div>
    </section>
  );
};
