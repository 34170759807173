import gql from "graphql-tag";
import { Cards as CardsProps } from "../../../../__generated__/Cards";
import { ContentfulComponentType } from "../types";
import styles from "./ContentfulCards.module.css";
import { TextAndMediaCard } from "./TextAndMediaCard";
import ArticleCard from "components-shared/ArticleCard";
import TextFields from "../TextFields";
import Gutter from "ui-kit/Gutter";
import { ContentfulC2A } from "../ContentfulC2A";
import { FlexCol } from "ui-kit/Layout/Flex";

export const ContentfulCards: ContentfulComponentType<CardsProps> = ({
  highlight,
  title,
  text,
  cardsCollection,
  c2A,
}) => {
  const cards = cardsCollection?.items ?? [];
  const isMultiple = cards.length > 2;

  return (
    <div className={styles.container}>
      <TextFields highlight={highlight} title={title} text={text} center />
      <div
        className={
          isMultiple
            ? styles.cardsContainerMultiple
            : styles.cardsContainerSingle
        }
      >
        {cards.map((props) => {
          if (props?.__typename === "ComponentTextAndMedia") {
            return (
              <TextAndMediaCard
                {...props}
                key={`${props.__typename}-${props.title}`}
              />
            );
          }

          if (props?.__typename === "Article") {
            return (
              <ArticleCard
                article={props}
                key={`${props.__typename}-${props.title}`}
              />
            );
          }

          return null;
        })}
      </div>
      {c2A && (
        <FlexCol alignItems="center" justifyContent="center">
          <Gutter size={3} />
          <ContentfulC2A {...c2A} />
        </FlexCol>
      )}
    </div>
  );
};

ContentfulCards.fragment = gql`
  fragment Cards on ComponentCards {
    highlight
    title
    text
    cardsCollection(limit: 6) {
      items {
        ...TextAndMedia
        ...ArticleCardArticle
      }
    }
    c2A {
      ...C2A
    }
    sys {
      id
      publishedAt
    }
  }
  ${ArticleCard.fragments.article}
`;
