import { resumePreviewStore } from "components/Signup/flow/utils/previewStore";
import { ResumeDraftState } from "components/Signup/store/state/types";
import { useEffect, useState } from "react";

export function useResumePreviewStore() {
  const [signupState, setSignupState] = useState<ResumeDraftState | null>(null);

  useEffect(() => {
    const resumedState = resumePreviewStore.load();

    if (resumedState?.previewId) {
      setSignupState({
        isLiability: resumedState.isLiability,
        previewId: resumedState.previewId,
        name: resumedState.name,
        species: resumedState.species,
        isMultipet: resumedState.isMultipet,
      });
    }
  }, []);

  return signupState;
}
