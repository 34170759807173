import { FC, ReactNode } from "react";
import styles from "./Background.module.css";

type BackgroundProps = {
  background?: string | null;
  children: ReactNode;
};

const Background: FC<BackgroundProps> = ({ background, children }) => (
  <div className={styles.container}>
    {background && <div className={styles.background} style={{ background }} />}
    <div className={styles.content}>{children}</div>
  </div>
);

export default Background;
