import React, { FC } from "react";
import styles from "./MediaCard.module.css";
import Link from "next/link";
import Image from "next/image";
import { useWebTranslation } from "../../../../translations";
import { Typography } from "../../../../ui-kit/Typography";

type Props = {
  imageSrc: string;
  text: string;
  date: string;
  url: string;
};

export const MediaCard: FC<Props> = ({ date, imageSrc, url, text }) => {
  const { t } = useWebTranslation();
  return (
    <div className={styles.mediaItem}>
      <div>
        <div className={styles.mainImage}>
          <Image
            src={imageSrc}
            alt="media-img"
            fill
            sizes="100vw"
            style={{ objectFit: "cover" }}
          />
        </div>
        <Typography variant="h4">{text}</Typography>
      </div>
      <div className={styles.row}>
        <div className={styles.date}>{date}</div>
        <Link href={url} legacyBehavior>
          <button type="button">
            <div>{t("about_us.media.section_btn")}</div>
            <Image
              width={25}
              height={25}
              className={styles.icon}
              src="/images/icons/external-link-blue.svg"
              alt="external-link"
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
          </button>
        </Link>
      </div>
    </div>
  );
};
