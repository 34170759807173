import React, { FC, ReactNode } from "react";
import Link from "next/link";
import Colors from "../ui-kit/colors";

interface AnchorProps {
  href: string;
  children: ReactNode;
}

const Anchor: FC<AnchorProps> = ({ href, children }) => {
  return (
    <Link href={href} style={{ color: Colors.blue300, fontWeight: 500 }}>
      {children}
    </Link>
  );
};

export default Anchor;
