import { FC } from "react";
import { ContentfulHero, HeroTypeEnum } from "../../ContentfulHero";
import { Hero } from "../../../../../__generated__/Hero";

type PremadeHeroProps = {
  variant: string;
};

enum HeroVariantEnum {
  GermanHomepage = "germanHomepage",
  SwedishHomepage = "swedishHomepage",
  PuppyCampaign = "puppyCampaign",
  KittenCampaign = "kittenCampaign",
  GermanOOHCampaign = "germanOOHCampaign",
}

const getHeroType = (variant: HeroVariantEnum) => {
  if (
    [HeroVariantEnum.GermanHomepage, HeroVariantEnum.SwedishHomepage].includes(
      variant
    )
  ) {
    return HeroTypeEnum.StartpageHero;
  }

  if (variant === HeroVariantEnum.PuppyCampaign) {
    return HeroTypeEnum.PuppyCampaignHero;
  }

  if (variant === HeroVariantEnum.KittenCampaign) {
    return HeroTypeEnum.KittenCampaignHero;
  }

  if (variant === HeroVariantEnum.GermanOOHCampaign) {
    return HeroTypeEnum.GermanOOHCampaignHero;
  }
};

const PremadeHero: FC<PremadeHeroProps> = ({ variant }) => {
  const heroType = getHeroType(variant as HeroVariantEnum);

  if (!heroType) {
    console.warn(`Unknown premade hero variant ${variant}`);
    return null;
  }

  return <ContentfulHero {...({} as Hero)} type={heroType} />;
};

export default PremadeHero;
