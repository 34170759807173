import React from "react";
import { gql } from "@apollo/client";
import { ContentfulComponentType } from "../types";
import ResponsiveGutter from "../../../ui-kit/ResponsiveGutter";

type Props = {
  size: "xs" | "small" | "medium" | "large";
  fixedSize: number;
};

export const ContentfulGutter: ContentfulComponentType<Props> = (props) => {
  const getGutterProps = () => {
    if (props.fixedSize) {
      return {
        mobile: props.fixedSize,
        tablet: props.fixedSize,
        desktop: props.fixedSize,
      };
    }

    switch (props.size) {
      case "xs":
        return {
          mobile: 2,
          tablet: 2,
          desktop: 4,
        };
      case "small":
        return {
          mobile: 4,
          tablet: 4,
          desktop: 8,
        };
      case "large":
        return {
          mobile: 8,
          tablet: 8,
          desktop: 16,
        };
      default:
      case "medium":
        return {
          mobile: 6,
          tablet: 6,
          desktop: 12,
        };
    }
  };

  return <ResponsiveGutter {...getGutterProps()} />;
};

ContentfulGutter.fragment = gql`
  fragment Gutter on ComponentGutter {
    size
    fixedSize
    sys {
      id
      publishedAt
    }
  }
`;
