import Markdown from "react-markdown";
import Gutter, { GutterSizes } from "../../../ui-kit/Gutter";
import { Typography } from "../../../ui-kit/Typography";
import styles from "./TextFields.module.css";
import { FC } from "react";
import { TypographyVariant } from "ui-kit/Typography/types";
import Colors from "ui-kit/colors";

type TextFieldsProps = {
  highlight?: string | null;
  highlightColor?: keyof typeof Colors;
  title?: string | null;
  intro?: string | null;
  text?: string | null;
  center?: boolean;
  variantTitle?: keyof typeof TypographyVariant;
  variantText?: keyof typeof TypographyVariant;
  gutterGap?: GutterSizes;
  gutterBottom?: GutterSizes;
};

const TextFields: FC<TextFieldsProps> = ({
  highlight,
  highlightColor,
  title,
  intro,
  text,
  center,
  variantTitle = "h2",
  variantText = "body1",
  gutterGap = 2,
  gutterBottom = 5,
}) => (
  <div
    className={styles.textContainer}
    style={center ? { textAlign: "center" } : {}}
  >
    {highlight && (
      <>
        <Typography
          style={{ textTransform: "uppercase" }}
          variant="body3"
          color={highlightColor ?? "navy500"}
        >
          <strong>{highlight}</strong>
        </Typography>
        <Gutter size={gutterGap} />
      </>
    )}
    {title && <Typography variant={variantTitle}>{title}</Typography>}
    {intro && (
      <>
        <Gutter size={gutterGap} />
        <Typography variant="body1" color="grey500">
          <strong>{intro}</strong>
        </Typography>
      </>
    )}
    {text && (
      <>
        <Gutter size={gutterGap} />
        <Typography variant={variantText} color="grey500">
          {text.split("\n").map((section, index) =>
            section ? (
              <Markdown
                key={section}
                allowedElements={["span", "strong", "em", "a"]}
                unwrapDisallowed
                className={styles.markdown}
              >
                {section}
              </Markdown>
            ) : (
              <br key={index} />
            )
          )}
        </Typography>
      </>
    )}
    {(highlight || title || intro || text) && <Gutter size={gutterBottom} />}
  </div>
);

export default TextFields;
