import React, { memo } from "react";
import { useWebTranslation } from "../../../../translations";

const ssr = typeof window === "undefined";

const TassfondenAddToCartButton = () => {
  const { t } = useWebTranslation();
  if (ssr) {
    return null;
  }

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div id="product-component-1645042256078"></div>
      <script type="text/javascript">
        {/* @ts-ignore */}
        {(function () {
          var scriptURL =
            "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
          if (window.ShopifyBuy) {
            if (window.ShopifyBuy.UI) {
              ShopifyBuyInit();
            } else {
              loadScript();
            }
          } else {
            loadScript();
          }
          function loadScript() {
            var script = document.createElement("script");
            script.async = true;
            script.src = scriptURL;
            (
              document.getElementsByTagName("head")[0] ||
              document.getElementsByTagName("body")[0]
            ).appendChild(script);
            script.onload = ShopifyBuyInit;
          }
          function ShopifyBuyInit() {
            // @ts-ignore
            var client = ShopifyBuy.buildClient({
              domain: "tassfonden.myshopify.com",
              storefrontAccessToken: "a4ad8c90a7b1a03ca1224b9d782d02ee",
            });
            // @ts-ignore
            ShopifyBuy.UI.onReady(client).then(function (ui) {
              ui.createComponent("product", {
                id: "7471346712795",
                node: document.getElementById(
                  "product-component-1645042256078"
                ),
                moneyFormat: "%7B%7Bamount_no_decimals%7D%7D%20kr",
                options: {
                  product: {
                    styles: {
                      product: {
                        "@media (min-width: 500px)": {
                          "max-width": "calc(25% - 20px)",
                          "margin-left": "20px",
                          "margin-bottom": "50px",
                        },
                      },
                      button: {
                        "font-family": "Roboto, sans-serif",
                        "font-weight": "bold",
                        "font-size": "16px",
                        "padding-top": "12px",
                        "padding-bottom": "12px",
                        ":hover": {
                          "background-color": "#2241d1",
                        },
                        "background-color": "#14267b",
                        ":focus": {
                          "background-color": "#2241d1",
                        },
                        "border-radius": "100px",
                        "padding-left": "56px",
                        "padding-right": "56px",
                        "max-width": "241px",
                        width: "100%",
                      },
                      quantityInput: {
                        "font-size": "16px",
                        "padding-top": "16px",
                        "padding-bottom": "16px",
                      },
                    },
                    contents: {
                      img: false,
                      title: false,
                      price: false,
                    },
                    text: {
                      button: t("shopify.product.btn"),
                    },
                    googleFonts: ["Roboto"],
                  },
                  productSet: {
                    styles: {
                      products: {
                        "@media (min-width: 500px)": {
                          "margin-left": "-20px",
                        },
                      },
                    },
                  },
                  modalProduct: {
                    contents: {
                      img: false,
                      imgWithCarousel: true,
                      button: false,
                      buttonWithQuantity: true,
                    },
                    styles: {
                      product: {
                        "@media (min-width: 500px)": {
                          "max-width": "100%",
                          "margin-left": "0px",
                          "margin-bottom": "0px",
                        },
                      },
                      button: {
                        "font-family": "Roboto, sans-serif",
                        "font-weight": "bold",
                        "font-size": "16px",
                        "padding-top": "16px",
                        "padding-bottom": "16px",
                        ":hover": {
                          "background-color": "#2241d1",
                        },
                        "background-color": "#14267b",
                        ":focus": {
                          "background-color": "#2241d1",
                        },
                        "border-radius": "12px",
                        "padding-left": "74px",
                        "padding-right": "74px",
                      },
                      quantityInput: {
                        "font-size": "16px",
                        "padding-top": "16px",
                        "padding-bottom": "16px",
                      },
                    },
                    googleFonts: ["Roboto"],
                    text: {
                      button: t("shopify.modal_product.btn"),
                    },
                  },
                  option: {},
                  cart: {
                    styles: {
                      button: {
                        "font-family": "Roboto, sans-serif",
                        "font-weight": "bold",
                        "font-size": "16px",
                        "padding-top": "16px",
                        "padding-bottom": "16px",
                        ":hover": {
                          "background-color": "#2241d1",
                        },
                        "background-color": "#14267b",
                        ":focus": {
                          "background-color": "#2241d1",
                        },
                        "border-radius": "12px",
                      },
                    },
                    text: {
                      title: t("shopify.cart.title"),
                      total: t("shopify.cart.total"),
                      empty: t("shopify.cart.empty"),
                      button: t("shopify.cart.button"),
                      notice: "",
                    },
                    popup: false,
                    googleFonts: ["Roboto"],
                  },
                  toggle: {
                    styles: {
                      toggle: {
                        "font-family": "Roboto, sans-serif",
                        "font-weight": "bold",
                        "background-color": "#14267b",
                        ":hover": {
                          "background-color": "#2241d1",
                        },
                        ":focus": {
                          "background-color": "#2241d1",
                        },
                      },
                      count: {
                        "font-size": "16px",
                      },
                    },
                    googleFonts: ["Roboto"],
                  },
                },
              });
            });
          }
        })()}
      </script>
    </div>
  );
};

export default memo(TassfondenAddToCartButton);
