import { useTranslationsReady, useWebTranslation } from "../../../translations";
import React from "react";
import ArticleCard from "../../../components-shared/ArticleCard";
import styles from "./ArticleList.module.css";
import Container from "../../../components-shared/Container";
import { ArticleCardArticle } from "../../../../__generated__/ArticleCardArticle";
import { gql } from "@apollo/client";
import { ContentfulComponentType } from "../types";
import { DocumentNode } from "graphql";
import { ComponentArticleList_componentArticleList } from "../../../../__generated__/ComponentArticleList";
import { ContentfulC2A } from "../ContentfulC2A";
import TextFields from "../TextFields";
import Gutter from "ui-kit/Gutter";
import { FlexCol } from "ui-kit/Layout/Flex";

export const ContentfulArticleList: ContentfulComponentType<ComponentArticleList_componentArticleList> & {
  fragmentFull: DocumentNode;
} = ({ description, title, articlesCollection, c2A }) => {
  useTranslationsReady();

  const { t } = useWebTranslation();
  const articles = articlesCollection?.items ?? [];

  return (
    <>
      <TextFields title={title} text={description} center />
      <div className={styles.content}>
        <Container style={{ backgroundColor: "white" }}>
          {articles.length ? (
            articles.map((item: any) => (
              <div key={item?.sys.id}>
                <ArticleCard
                  variant="white"
                  article={item as ArticleCardArticle}
                />
              </div>
            ))
          ) : (
            <div className={styles.noArticles}>{t("articles.empty")}</div>
          )}
        </Container>
      </div>
      {c2A && (
        <FlexCol alignItems="center" justifyContent="center">
          <Gutter size={3} />
          <ContentfulC2A {...c2A} />
        </FlexCol>
      )}
    </>
  );
};

ContentfulArticleList.fragment = gql`
  fragment ArticleList on ComponentArticleList {
    sys {
      publishedAt
      id
    }
  }
`;

ContentfulArticleList.fragmentFull = gql`
  query ComponentArticleList($id: String!, $locale: String, $preview: Boolean) {
    componentArticleList(id: $id, locale: $locale, preview: $preview) {
      title
      description
      #    FIXME QUERY COMPLEXITY. this overloads query heavily. Probably use another query for this
      articlesCollection(limit: 90) {
        items {
          ...ArticleCardArticle
          sys {
            id
          }
        }
      }
      c2A {
        ...C2A
      }
      sys {
        publishedAt
      }
    }
  }
  ${ArticleCard.fragments.article}
  ${ContentfulC2A.fragment}
`;
