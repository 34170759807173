import { ContentfulComponentType } from "../types";
import gql from "graphql-tag";
import { Typography } from "../../../ui-kit/Typography";
import Image from "next/image";
import React from "react";
import styles from "./ContentfulList.module.css";
import classNames from "classnames";

type Props = {
  display: "default" | "bubbles" | "stars" | "dots";
  items: string[];
};

export const ContentfulList: ContentfulComponentType<Props> = ({
  items,
  display,
}) => {
  if (display === "stars") {
    return (
      <ul className={styles.unstyledList}>
        {items.map((item) => (
          <li key={item} className={styles.starRow}>
            <Image
              src="/images/check-badge.svg"
              height={24}
              width={24}
              alt="check-badge"
            />
            <Typography
              key={item}
              style={{ paddingLeft: 8, lineHeight: "24px" }}
            >
              {item}
            </Typography>
          </li>
        ))}
      </ul>
    );
  }

  if (display === "bubbles") {
    return (
      <ul className={classNames(styles.unstyledList, styles.checkmarkList)}>
        {items.map((item) => (
          <li key={item} className={classNames(styles.bubbles)}>
            {item}
          </li>
        ))}
      </ul>
    );
  }

  return (
    <ul
      className={classNames(
        styles.list,
        display !== "dots" && styles.unstyledList,
        display !== "dots" && styles.checkmarkList
      )}
    >
      {items.map((item) => (
        <li key={item}>
          <Typography key={item}>{item}</Typography>
        </li>
      ))}
    </ul>
  );
};

ContentfulList.fragment = gql`
  fragment List on ComponentList {
    display
    items
    sys {
      id
    }
  }
`;
