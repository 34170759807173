import Colors from "ui-kit/colors";

export enum ThemeEnum {
  None = "None",
  Blue = "Blue",
  Green = "Green",
  Grey = "Grey",
  Yellow = "Yellow",
}

export const hasTheme = ({ theme }: { theme?: string | null } = {}) =>
  theme && theme !== ThemeEnum.None;

const createGradient = (color: keyof typeof Colors) =>
  `linear-gradient(135deg, ${Colors[color] + "00"} 15%, ${Colors[color]})`;

export const getThemeColors = (
  theme: string | null
): {
  backgroundColor: keyof typeof Colors;
  backgroundGradient: string;
  highlightColor: keyof typeof Colors;
} | null => {
  if (theme === ThemeEnum.Blue) {
    return {
      backgroundColor: "navy100",
      backgroundGradient: createGradient("navy200"),
      highlightColor: "navy500",
    };
  }

  if (theme === ThemeEnum.Green) {
    return {
      backgroundColor: "forrest100",
      backgroundGradient: createGradient("forrest200"),
      highlightColor: "green300",
    };
  }

  if (theme === ThemeEnum.Grey) {
    return {
      backgroundColor: "offwhite",
      backgroundGradient: createGradient("offwhite"),
      highlightColor: "grey500",
    };
  }

  if (theme === ThemeEnum.Yellow) {
    return {
      backgroundColor: "sun100",
      backgroundGradient: createGradient("sun200"),
      highlightColor: "navy500",
    };
  }

  return null;
};
