import { gql } from "@apollo/client";
import { ContentfulComponentType } from "../types";
import { ComponentMap } from "./type-mappings";
import TextFields from "../TextFields";
import { Component } from "../../../../__generated__/Component";
import Background from "../Background";
import { getThemeColors } from "../theme";
import { FlexCol } from "ui-kit/Layout/Flex";
import Gutter from "ui-kit/Gutter";
import { ContentfulC2A } from "../ContentfulC2A";
import Colors from "ui-kit/colors";

export const ContentfulComponent: ContentfulComponentType<Component> = ({
  highlight,
  title,
  type,
  theme,
  props,
  c2A,
}) => {
  const themeColors = getThemeColors(theme);
  const Component = ComponentMap[type ?? ""];

  if (!Component) {
    console.warn(`Unknown component type ${type}`);
    return null;
  }

  return (
    <Background background={themeColors && Colors[themeColors.backgroundColor]}>
      <TextFields
        highlight={highlight}
        highlightColor={themeColors?.highlightColor}
        title={title}
        center
      />
      <Component {...props} />
      {c2A && (
        <FlexCol alignItems="center" justifyContent="center">
          <Gutter size={3} />
          <ContentfulC2A {...c2A} />
        </FlexCol>
      )}
    </Background>
  );
};

ContentfulComponent.fragment = gql`
  fragment Component on Component {
    highlight
    title
    type
    theme
    props
    c2A {
      ...C2A
    }
    sys {
      id
      publishedAt
    }
  }
`;
