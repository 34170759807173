import React, { ReactElement } from "react";
import { gql } from "@apollo/client";
import Link from "next/link";
import { ArticleCardArticle } from "../../__generated__/ArticleCardArticle";
import ContentfulImage from "./ContentfulMedia";
import { Typography } from "../ui-kit/Typography";
import { FlexCol, FlexRow } from "../ui-kit/Layout/Flex";
import { Pill } from "../ui-kit/Pill";
import Gutter from "../ui-kit/Gutter";
import ContentfulMedia from "./ContentfulMedia";
import styles from "./ArticleCard.module.css";

type Props = {
  article: ArticleCardArticle;
};

const ArticleCard = ({ article }: Props): ReactElement => {
  // keeping 'category' during migration, will remove
  const topic =
    article.topicsCollection?.items[0]?.name ?? article.category?.name ?? "";

  return (
    <Link href={article.uri?.toLowerCase() ?? ""}>
      <div className={styles.cardContainer}>
        <ContentfulMedia
          media={article.mainImage}
          sizes="370px"
          display="header"
        />
        <FlexCol className={styles.textContainer}>
          <FlexRow justifyContent="space-between">
            {topic && (
              <div>
                <Gutter size={1} horizontal />
                <Pill
                  text={topic}
                  backgroundColor="blue100"
                  color={"blue300"}
                />
              </div>
            )}
            {article.readTime && (
              <Typography
                color={"grey400"}
              >{`${article.readTime} min`}</Typography>
            )}
          </FlexRow>
          <Gutter size={2} />
          <Typography variant="h3">{article.title}</Typography>
          <Gutter size={2} />
          <Typography color={"grey500"}>{article.shortText}</Typography>
        </FlexCol>
      </div>
    </Link>
  );
};

ArticleCard.defaultProps = {
  variant: "white",
};

ArticleCard.fragments = {
  article: gql`
    fragment ArticleCardArticle on Article {
      sys {
        id
        firstPublishedAt
      }
      uri
      mainImage {
        sys {
          id
        }
        ...MediaAsset
      }
      video {
        sys {
          id
        }
      }
      category {
        sys {
          id
        }
        name
      }
      topicsCollection(limit: 2) {
        items {
          sys {
            id
          }
          name
        }
      }
      title
      metadataTitle
      readTime
      shortText
    }
    ${ContentfulImage.fragment}
  `,
};

export default ArticleCard;
