import React from "react";
import Image from "next/image";
import styles from "./FirstVetCard.module.css";
import { Typography } from "../../../ui-kit/Typography";
import Gutter from "../../../ui-kit/Gutter";
import { gql } from "@apollo/client";
import { ContentfulComponentType } from "../types";
import ResponsiveGutter from "../../../ui-kit/ResponsiveGutter";

type Props = {
  title: string;
  text: string;
};

export const ContentfulFirstVetCard: ContentfulComponentType<Props> = ({
  text,
  title,
}) => {
  return (
    <>
      <ResponsiveGutter mobile={2} tablet={2} desktop={4} />
      <div className={styles.card}>
        <div style={{ maxWidth: 472 }}>
          <Typography component={"div"} variant="h3">
            {title}
          </Typography>
          <Gutter size={2} />
          <Typography component={"div"} color={"grey500"}>
            {text}
          </Typography>
        </div>
        <div className={styles.imgContainer}>
          <Image
            src={"/images/insurance/firstvet-logo.svg"}
            alt="FirstVet"
            width={300}
            height={70}
            style={{
              maxWidth: "100%",
              height: "auto",
            }}
          />
        </div>
      </div>
    </>
  );
};

ContentfulFirstVetCard.fragment = gql`
  fragment FirstVetCard on ComponentFirstVetCard {
    title
    text
    sys {
      id
      publishedAt
    }
  }
`;
