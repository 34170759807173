import gql from "graphql-tag";
import { TextAndList as TextAndListProps } from "../../../../__generated__/TextAndList";
import { ContentfulComponentType } from "../types";
import styles from "./TextAndList.module.css";
import TextFields from "../TextFields";
import Gutter from "ui-kit/Gutter";
import { ContentfulC2A } from "../ContentfulC2A";
import { getThemeColors } from "../theme";
import List, { ListStyleEnum } from "../List";
import Background from "../Background";
import Colors from "ui-kit/colors";

export const ContentfulTextAndList: ContentfulComponentType<
  TextAndListProps
> = ({
  highlight,
  title,
  intro,
  text,
  listItemsCollection,
  listLayout,
  listStyle,
  c2A,
  layout,
  theme,
}) => {
  const themeColors = getThemeColors(theme);
  const listItems = listItemsCollection?.items ?? [];

  return (
    <Background background={themeColors && Colors[themeColors.backgroundColor]}>
      <div className={layout! ? styles.containerReverse : styles.container}>
        <div className={styles.textContainer}>
          <TextFields
            title={title}
            highlight={highlight}
            highlightColor={themeColors?.highlightColor}
            text={text}
            intro={intro}
            variantText="body2"
            gutterBottom={0}
          />
          {c2A && (
            <>
              <Gutter size={4} />
              <ContentfulC2A {...c2A} />
            </>
          )}
        </div>
        <div className={styles.listContainer}>
          <List
            items={listItems}
            listStyle={listStyle as ListStyleEnum}
            collapsible={!!listLayout}
          />
        </div>
      </div>
    </Background>
  );
};

ContentfulTextAndList.fragment = gql`
  fragment TextAndList on ComponentTextAndList {
    highlight
    title
    intro
    text
    theme
    layout
    listItemsCollection(limit: 10) {
      items {
        title
        text
        icon
      }
    }
    listLayout
    listStyle
    c2A {
      ...C2A
    }
    list
    sys {
      id
      publishedAt
    }
  }
`;
