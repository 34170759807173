import { FC, useState } from "react";
import styles from "./List.module.css";
import { Typography } from "ui-kit/Typography";
import classNames from "classnames";
import Icon from "ui-kit/Icon";
import ListStyle from "./ListStyle";
import ItemText from "./ItemText";

export enum ListStyleEnum {
  None = "None",
  Bullet = "Bullet",
  Check = "Check",
  Number = "Number",
  Icon = "Icon",
}

export type ListItem = {
  title: string | null;
  text: string | null;
  icon: string | null;
};

type ListProps = {
  listStyle?: ListStyleEnum;
  collapsible?: boolean;
  items: (ListItem | null)[];
};

const VisibleItem = ({
  index,
  item,
  listStyle,
}: {
  index: number;
  item: ListItem;
  listStyle?: ListStyleEnum;
}) => {
  const { title, text, icon } = item;
  const shouldHaveReducedSpacing = [
    ListStyleEnum.Bullet,
    ListStyleEnum.Check,
  ].includes(listStyle ?? ListStyleEnum.None);
  const shouldHaveSeparator = [
    ListStyleEnum.None,
    ListStyleEnum.Number,
  ].includes(listStyle ?? ListStyleEnum.None);

  return (
    <>
      <li
        className={styles.item}
        style={{
          padding: shouldHaveReducedSpacing ? "4px 0" : "8px 0",
        }}
      >
        <ListStyle listStyle={listStyle} icon={icon} index={index} />
        <Typography className={styles.title}>
          <strong>{title}</strong>
        </Typography>
        {text && (
          <ItemText text={text} noGutter={shouldHaveReducedSpacing} isVisible />
        )}
      </li>
      {shouldHaveSeparator && <div className={styles.separator} />}
    </>
  );
};

const CollapsibleItem = ({
  openIndexes,
  setOpenIndexes,
  index,
  item,
  listStyle,
}: {
  openIndexes: number[];
  setOpenIndexes: (indexes: number[]) => void;
  index: number;
  item: ListItem;
  listStyle?: ListStyleEnum;
}) => {
  const isVisible = openIndexes.includes(index);
  const { title, text, icon } = item;
  const shouldHaveReducedSpacing = [
    ListStyleEnum.Bullet,
    ListStyleEnum.Check,
  ].includes(listStyle ?? ListStyleEnum.None);

  return (
    <>
      <li
        className={classNames(styles.item, styles.collapsible)}
        onClick={() =>
          setOpenIndexes(
            isVisible
              ? openIndexes.filter((i) => i !== index)
              : [...openIndexes, index]
          )
        }
        style={{
          padding: shouldHaveReducedSpacing ? "8px 0" : "16px 0",
        }}
        role="button"
      >
        <ListStyle listStyle={listStyle} icon={icon} index={index} />
        <Typography className={styles.title}>
          <strong>{title}</strong>
        </Typography>
        <Icon
          name={isVisible ? "chevron-up" : "chevron-down"}
          size={20}
          color="black"
          styles={{ marginRight: -4, marginLeft: "auto" }}
        />
        {text && <ItemText text={text} isVisible={isVisible} />}
      </li>
      <div className={styles.separator} />
    </>
  );
};

const List: FC<ListProps> = ({ listStyle, items, collapsible }) => {
  const [openIndexes, setOpenIndexes] = useState<number[]>([]);

  return (
    <ul className={classNames(styles.list)}>
      {items
        .flatMap((item) => (item ? [item] : []))
        .map((item, index) =>
          collapsible ? (
            <CollapsibleItem
              key={item.title}
              openIndexes={openIndexes}
              setOpenIndexes={setOpenIndexes}
              index={index}
              item={item}
              listStyle={listStyle}
            />
          ) : (
            <VisibleItem
              key={item.title}
              index={index}
              item={item}
              listStyle={listStyle}
            />
          )
        )}
    </ul>
  );
};

export default List;
