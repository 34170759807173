import React, { CSSProperties, ReactElement } from "react";
import Icon from "./Icon";
import styles from "./IconButton.module.css";
import { Typography } from "ui-kit/Typography";
import Gutter from "ui-kit/Gutter";
import Colors from "ui-kit/colors";
import { TypographyVariant } from "ui-kit/Typography/types";

interface IconButtonProps {
  onPress: () => void;
  icon: string;
  color: keyof typeof Colors;
  backgroundColor?: keyof typeof Colors;
  disabled?: boolean;
  size?: number;
  style?: CSSProperties;
  className?: string;
  title?: string;
  titleVariant?: keyof typeof TypographyVariant;
}
const DEFAULT_ICON_SIZE = 17;

const IconButton = ({
  onPress,
  icon,
  color,
  backgroundColor,
  disabled,
  size,
  style,
  className,
  title,
  titleVariant,
}: IconButtonProps): ReactElement => (
  <button
    type="button"
    disabled={disabled}
    onClick={onPress}
    className={`${styles.iconButton} ${className}`}
    style={{
      ...(backgroundColor ? { backgroundColor: Colors[backgroundColor] } : {}),
      ...(style ? { ...style } : {}),
    }}
  >
    {title ? (
      <>
        <Typography variant={titleVariant} color={color}>
          <strong>{title}</strong>
        </Typography>
        <Gutter size={1} horizontal />
      </>
    ) : null}
    <Icon
      size={size ?? DEFAULT_ICON_SIZE}
      color={Colors[color]}
      name={icon}
      styles={{ height: size ?? DEFAULT_ICON_SIZE }}
    />
  </button>
);

IconButton.defaultProps = {
  backgroundColor: undefined,
  disabled: false,
  size: 19,
  style: undefined,
};

export default IconButton;
